import UploadFilesService from '@/services/upload-files';

export default {
  state: {
    files: {
      Inbox: [],
      Archived: [],
    },
    filesLoaded: false,
  },
  mutations: {
    RESET_FILES(state) {
      state.files = {};
      state.filesLoaded = false;
    },
    ADD_FILE(state, file) {
      state.files.Inbox = [...state.files.Inbox, file];
    },
    SET_FILES(state, files) {
      state.files.Inbox = files.filter((file) => file.meta.labels.inbox !== 'archive');
      state.files.Archived = files.filter((file) => file.meta.labels.inbox === 'archive');
    },
    SET_FILES_LOADED(state, filesLoaded) {
      state.filesLoaded = filesLoaded;
    },
  },
  actions: {
    resetFiles({ commit }) {
      commit('RESET_FILES');
    },
    async fetchFile({ commit }, fileName) {
      const file = await UploadFilesService.getFile(fileName);
      commit('ADD_FILE', file);
    },
    async fetchFiles({ commit }, queryParams) {
      commit('SET_FILES_LOADED', false);
      const files = await UploadFilesService.getFiles(queryParams);
      commit('SET_FILES', files.items);
      commit('SET_FILES_LOADED', true);
    },
    async putUploadFile({ dispatch }, body) {
      await UploadFilesService.putUploadFile(body);
    },
    async putUploadChunks({ dispatch }, body) {
      await UploadFilesService.putUploadChunk(body);
    },
  },
  getters: {
    files: (state) => state.files,
    filesLoaded: (state) => state.filesLoaded,
  },
};
