import cloneDeep from 'lodash.clonedeep';

export default {
  state: {
    abortComplete: true,
    abortController: window.AbortController ? new AbortController() : null,
    requests: [],
  },
  mutations: {
    REMOVE_REQUEST(state, requestId) {
      const index = state.requests.findIndex((r) => r.id === requestId);
      state.requests.splice(index, 1);
    },
    RESET_REQUESTS(state) {
      if (state.abortController) {
        state.abortController.abort();
        state.abortComplete = true;
        state.abortController = new AbortController();
      }
      state.requests = [];
    },
    SET_ABORT_COMPLETE(state, status) {
      state.abortComplete = status;
    },
    SET_ABORT_CONTROLLER(state, abortController) {
      state.abortController = abortController;
    },
    SET_REQUEST(state, request) {
      if (!request.preventAbort && !request.init.signal) {
        request.init.signal = state.abortController ? state.abortController.signal : null;
      }
      state.requests = [...state.requests.filter((r) => r.id !== request.id), request];
    },
    SET_REQUEST_VALUE(state, requestData) {
      const request = state.requests.find((r) => r.id === requestData.id);
      if (request) {
        request[requestData.key] = requestData.value;
        state.requests = [...state.requests.filter((r) => r.id !== request.id), request];
      }
    },
    SET_REQUESTS(state, requests) {
      state.requests = requests;
    },
  },
  actions: {
    abortRequests({ commit, state }, networkError) {
      if (state.requests.length > 0 && state.abortController && state.abortComplete) {
        commit('SET_ABORT_COMPLETE', false);
        state.abortController.abort();
        if (networkError) {
          const retryRequest = state.requests[0];
          commit('SET_REQUEST_VALUE', { id: retryRequest.id, key: 'isNetworkRetry', value: true });
          retryRequest.send();
        }
        commit('SET_ABORT_CONTROLLER', new AbortController());
        commit('SET_ABORT_COMPLETE', true);
      }
    },
    resendRequests({ commit, state, rootGetters }) {
      state.requests.forEach((r) => {
        const init = cloneDeep(r.init);
        init.headers.Authorization = `Bearer ${rootGetters.accessToken}`;
        init.headers.Claims = rootGetters.claimsToken;
        init.signal = state.abortController ? state.abortController.signal : null;
        commit('SET_REQUEST_VALUE', { id: r.id, key: 'init', value: init });
        r.handleFetch();
      });
    },
    removeRequest({ commit }, requestId) {
      commit('REMOVE_REQUEST', requestId);
    },
    resetRequests({ commit }) {
      commit('RESET_REQUESTS');
    },
    setAbortController({ commit }, abortController) {
      commit('SET_ABORT_CONTROLLER', abortController);
    },
    setRequest({ commit }, request) {
      commit('SET_REQUEST', request);
    },
    setRequestValue({ commit }, requestData) {
      commit('SET_REQUEST_VALUE', requestData);
    },
    setRequests({ commit }, requests) {
      commit('SET_REQUESTS', requests);
    },
  },
  getters: {
    abortController: (state) => state.abortController,
    requests: (state) => state.requests,
  },
};
