<template>
  <div class="user-portal">
    <div
      v-for="item in capableItems"
      :key="item.link"
      class="portal-card-container"
      xl3
      lg4
      md5
      sm6
      xs7>
      <v-card
        class="portal-card mx-7 mb-7"
        width="310">
        <a
          :href="`/#/${item.link}`"
          class="portal-link">
          <v-card-title class="text-white bg-primary justify-center">
            {{ item.title }}
          </v-card-title>
          <v-icon
            class="text-secondary ma-3 center"
            :icon="item.icon"/>
          <div class="portal-link-section pa-4 bg-black">
            <span
              class="text-white"
              v-text="item.text"/>
          </div>
        </a>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserPortal',
  data() {
    return {
      items: [
        {
          title: 'Single Sign-On',
          capabilities: ['admin::sso'],
          icon: 'mdi-shield-lock',
          text: "Configure single sign-on for your organization's users",
          link: 'user-admin/sso-config',
        },
        {
          title: 'Users',
          capabilities: ['admin::users'],
          icon: 'mdi-account-group',
          text: 'Manage user accounts for your organization',
          link: 'user-admin/user-config',
        },
      ],
    };
  },
  computed: {
    capableItems() {
      return this.items.filter((item) => item.capabilities.some((cap) => this.userHasCapability(cap)));
    },
    ...mapGetters([
      'userHasCapability',
    ]),
  },
  mounted() {
    const now = moment();
    const menuData = {
      timestamp: now,
      menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
    };
    this.setMenuNavigationEnd(menuData);
  },
  methods: {
    navigateTo(link) {
      this.$router.push({ name: link });
    },
    ...mapActions([
      'setMenuNavigationEnd',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.user-portal {
  padding: 3rem;
  display: flex;
}
.portal-card-container {
  min-width: 250px;
  max-width: 380px;
}
.portal-card {
  @import '../../sass/colors.scss';
  display: inline-block;
  text-align: center;

  i.mdi {
    font-size: 200px;
    margin: 0 auto;
  }

  &:hover {
    cursor: pointer;
  }

  li {
    list-style-type: circle;
  }

  a.portal-link {
    text-decoration: none;
  }
  .portal-link-section {
    height: 95px;
    overflow: auto;
  }
}
</style>
