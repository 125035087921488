// filter.js
// Filter class that defines properties and methods for a filter, including its settings and values.

import { getFilterSummary } from '@/lib/filter-methods';

export class Filter {
  constructor(
    autocompleteFieldOverride,
    autocompleteLoading,
    autocompleteMappedField,
    autocompleteSubject,
    capabilities,
    dashboardAutocompleteSubject,
    dashboardDefaultValue,
    dashboardDisplayName,
    defaultValue,
    displayName,
    facets,
    fieldName,
    hidden,
    id,
    isOption,
    mappedValues,
    possibleValues,
    required,
    tenantSpecific,
    type,
    value,
    multiple,
  ) {
    this.autocompleteFieldOverride = autocompleteFieldOverride;
    this.autocompleteLoading = autocompleteLoading;
    this.autocompleteMappedField = autocompleteMappedField;
    this.autocompleteSubject = autocompleteSubject;
    this.capabilities = capabilities;
    this.dashboardAutocompleteSubject = dashboardAutocompleteSubject;
    this.dashboardDefaultValue = dashboardDefaultValue;
    this.dashboardDisplayName = dashboardDisplayName;
    this.defaultValue = defaultValue;
    this.facets = facets;
    this.displayName = displayName;
    this.fieldName = fieldName;
    this.hidden = hidden;
    this.id = id;
    this.isOption = isOption;
    this.mappedValues = mappedValues;
    this.possibleValues = possibleValues;
    this.required = required;
    this.tenantSpecific = tenantSpecific;
    this.type = type;
    this.value = value || null;
    this.multiple = multiple;
  }

  // Get filter summary statement
  get filterSummary() {
    return getFilterSummary(this);
  }

  // Determine if filter is set to its default value
  get isDefault() {
    return !this.value;
  }

  // Take props object and convert it to Filter class properties
  setProps(propsObj) {
    Object.keys(propsObj).forEach((prop) => {
      // Convert prop to camelCase (e.g., if it came from API)
      const camelProp = prop.replace(/_([a-z])/g, (p) => p[1].toUpperCase());
      this[camelProp] = propsObj[prop];
    });
  }
}

export default {
  Filter,
};
