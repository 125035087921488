<template>
  <v-menu
    v-model="menu"
    transition="slide-x-transition"
    content-class="menu"
    width="230"
    height="90%">
    <template #activator="{ props }">
      <v-badge
        :model-value="errorState"
        color="warning"
        icon="mdi-alert"
        offset-x="25"
        offset-y="25"
        location="bottom">
        <v-btn
          v-bind="props"
          color="rgba(0, 0, 0, 0)"
          :class="{
            'w-100': expanded,
            'mx-0': expanded,
          }"
          :disabled="disabled"
          :icon="!expanded"
          variant="flat"
          @click="(!isCurrent && !hasSubMenus) ? goToMainPage() : null">
          <v-tooltip
            :disabled="(menu && hasSubMenus) || expanded"
            z-index="9"
            location="right">
            <template #activator="{ subMenuOn }">
              <v-icon
                v-bind="subMenuOn"
                :style="iconStyle ? iconStyle : null"
                :class="{
                  'menu-icon': true,
                  'bainbridge-green--text': isCurrent && !disabled,
                  'bainbridge-blue--text': !isCurrent && !disabled,
                  'bainbridge-grey--text': disabled,
                }"
                :icon="icon"/>
            </template>
            <span>{{ title }}</span>
          </v-tooltip>
          <v-list-subheader
            v-if="expanded"
            :class="{
              'bainbridge-green--text': isCurrent && !disabled,
              'bainbridge-blue--text': !isCurrent && !disabled,
              'bainbridge-grey--text': disabled,
              'current': isCurrent && !hasSubMenus,
              'top-menu-title': hasSubMenus,
              'pl-4': expanded,
            }"
            class="main-title pr-0"
            :title="title"/>
        </v-btn>
      </v-badge>
    </template>
    <v-card
      v-if="hasSubMenus"
      class="sub-menu-card">
      <v-list density="compact">
        <v-list-item
          v-if="!expanded">
          <v-list-subheader
            :class="{
              'bainbridge-green--text': isCurrent && !disabled,
              'bainbridge-blue--text': !isCurrent && !disabled,
              'bainbridge-grey--text': disabled,
            }"
            class="main-title sub-main-title pl-0">
            {{ title }}
          </v-list-subheader>
        </v-list-item>
        <div
          v-for="(sub, index) in subMenus"
          :key="sub.title">
          <v-list-item v-if="'sections' in sub">
            <v-list-subheader
              :class="{
                'bainbridge-green--text': isSubCurrent(sub) && !disabled,
                'bainbridge-blue--text': !isSubCurrent(sub) && !disabled,
                'bainbridge-grey--text': disabled,
                'mt-4': (index !== 0),
              }"
              class="header-title pl-4">
              {{ sub.title }}
            </v-list-subheader>
          </v-list-item>
          <v-list-item
            v-else
            @click="goToSubPage(sub)">
            <v-list-item-title
              :class="{
                'bainbridge-green--text': isSubCurrent(sub) && !disabled,
                'bainbridge-blue--text': !isSubCurrent(sub) && !disabled,
                'bainbridge-grey--text': disabled,
                'current': isSubCurrent(sub),
              }"
              class="sub-title">
              {{ sub.title }}
            </v-list-item-title>
          </v-list-item>
          <div v-if="'sections' in sub">
            <v-list-item
              v-for="section in sub.sections"
              :key="section.title"
              @click="goToSubSectionPage(sub, section)">
              <v-list-item-title
                :class="{
                  'bainbridge-green--text': isSubSectionCurrent(sub, section) && !disabled,
                  'bainbridge-blue--text': !isSubSectionCurrent(sub, section) && !disabled,
                  'bainbridge-grey--text': disabled,
                  'current': isSubSectionCurrent(sub, section),
                }"
                class="sub-title">
                {{ section.title }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { objectsEqual } from '@/lib/compare';

export default {
  name: 'NavMenu',
  props: {
    expanded: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    errorState: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: String,
      required: true,
    },
    iconStyle: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: () => '',
    },
    subMenus: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => '',
    },
    url: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      menu: false,
      queryPaths: ['Analytics', 'Pumps'],
    };
  },
  computed: {
    isCurrent() {
      return this.isMainMenuCurrent || this.subMenus.some((sub) => this.isSubCurrent(sub));
    },
    isMainMenuCurrent() {
      if (this.url) {
        if (this.hasSubMenus) {
          return `/${this.url}` === this.$route.path;
        } else {
          return this.$route.path.startsWith(`/${this.url}`);
        }
      } else if (this.name) {
        return this.name === this.$route.name;
      } else {
        return false;
      }
    },
    hasSubMenus() {
      return this.subMenus.length > 0;
    },
  },
  methods: {
    isSubSectionCurrent(sub, section) {
      if (this.url) {
        const sectionUrl = `/${this.url}/${section.url}`;
        const subIndex = this.subMenus.indexOf(sub);
        const sectionIndex = sub.sections.indexOf(section);
        return (sectionUrl === this.$route.path) || (subIndex === 0 && sectionIndex === 0 && this.isMainMenuCurrent);
      } else {
        return section.name === this.$route.name;
      }
    },
    isSubCurrent(sub) {
      if (this.url) {
        let current;
        if ('sections' in sub) {
          current = sub.sections.find((section) => this.isSubSectionCurrent(sub, section));
        } else {
          const subURL = `/${this.url}/${sub.url}`;
          current = subURL === this.$route.path;
        }
        const subIndex = this.subMenus.indexOf(sub);
        return current || (subIndex === 0 && this.isMainMenuCurrent);
      } else {
        return sub.name === this.$route.name;
      }
    },
    goToSubSectionPage(sub, section) {
      const name = this.url.charAt(0).toUpperCase() + this.url.slice(1);
      if (section.url) {
        const path = `/${this.url}/${section.url}`;
        const { query } = this.$route.query && (
          this.queryPaths.includes(this.$route.name)
        ) ? this.$route : { query: false };
        if (this.$route.path !== path || !objectsEqual(this.$route.query, query)) {
          this.$router.push({ name, params: { id: section.url }, query });
        }
      } else if (section.name && this.$route.name !== section.name) {
        this.$router.push({ name });
      }
    },
    goToSubPage(sub) {
      const name = this.url.charAt(0).toUpperCase() + this.url.slice(1);
      if (sub.url) {
        const path = `/${this.url}/${sub.url}`;
        const { query } = this.$route.query && (
          this.queryPaths.includes(this.$route.name)
        ) ? this.$route : { query: false };
        if (this.$route.path !== path || !objectsEqual(this.$route.query, query)) {
          this.$router.push({ name, params: { id: sub.url }, query });
        }
      } else if (sub.name && this.$route.name !== sub.name) {
        this.$router.push({ name: sub.name });
      }
    },
    goToMainPage() {
      if (this.url) {
        const path = `/${this.url}`;
        const { query } = this.$route.query && (
          this.queryPaths.includes(this.$route.name)
        ) ? this.$route : { query: false };
        if (this.$route.path !== path || !objectsEqual(this.$route.query, query)) {
          this.$router.push({ path, query });
        }
      } else if (this.name && this.$route.name !== this.name) {
        this.$router.push({ name: this.name });
      }
    },
  },
};
</script>

<style lang="scss">
// .sub-menu-title {
//   font-size: 20px !important;
//   padding-left: 0px !important;
//   text-transform: uppercase;
//   transform: translateX(-16px);
//   letter-spacing: .11em;
//   width: 100%;
//   font-weight: 500;
// }
// .header-title {
//   font-size: 18px !important;
//   letter-spacing: .11em;
//   width: 100%;
//   font-weight: 500;
// }
.menu {
  @import '../../sass/colors.scss';

  position: fixed;
  box-shadow: none;
  .sub-menu-card {
    transform: translateX(50px);
    box-shadow: none !important;
    background-color: $bainbridge-gray-light !important;
    .v-list {
      // Unfortunately, .application-light.v-list seems to
      // override the nav drawer styling, so we need to reiterate the color
      background-color: $bainbridge-gray-light !important;
    }
  }
  .sub-title {
    font-size: 15px !important;
    padding-left: 3rem;
    letter-spacing: .11em;
    vertical-align: middle;
    line-height: 2;
    height: 100%;
    height: 30px;
    font-weight: 500;
  }

}
</style>
