<template>
  <div
    class="file-container">
    <div
      v-if="sortedFiles"
      class="file-view"
      :class="selectedFile >= 0 ? 'file-view-selected' : ''">
      <div
        class="h-100 file-view-container">
        <div class="card-header">
          {{ selectedSource }}
          <div class="file-view-actions">
            <div>
              <ViewStateMenu
                :view-as="viewAs"
                @view-state="(state) => viewAs = state"/>
            </div>
            <div v-if="selectedSource !== 'Archived'">
              <FileUploadController/>
            </div>
          </div>
        </div>
        <div
          v-if="viewAs === 'iconsSmall' || viewAs === 'iconsLarge'"
          class="file-grid">
          <div
            v-for="(file, i) in sortedFiles"
            :key="file.spec.name">
            <FileTile
              :state="file.status.state"
              :size="viewAs"
              :title="file.spec.name"
              :is-selected="selectedFile === i"
              @file-clicked="seeFileDetails(i)"/>
          </div>
        </div>
        <ListView
          v-if="viewAs === 'list'"
          :selected-file="selectedFile"
          :source-files="sortedFiles"
          :sorter="sorter"
          @selected-file="(index) => seeFileDetails(index)"
          @sort-by="(type) => changeSortBy(type)"/>
      </div>
    </div>
    <div
      v-if="selectedFile >= 0"
      class="file-details">
      <FileDetails
        v-if="selectedFile >= 0"
        :is-archived="selectedSource === 'Archived'"
        :file="sortedFiles[selectedFile]"/>
    </div>
  </div>
</template>

<script>
import FileDetails from './FileDetails/FileDetails.vue';
import FileUploadController from './file-upload/FileUploadController/FileUploadController.vue';
import ViewStateMenu from './action-items/ViewStateMenu/ViewStateMenu.vue';
import ListView from './views/ListView/ListView.vue';
import FileTile from './views/FileTile/FileTile.vue';

export default {
  name: 'FileView',
  components: {
    FileTile,
    FileDetails,
    FileUploadController,
    ViewStateMenu,
    ListView,
  },
  props: {
    selectedSource: {
      type: String,
      required: true,
    },
    sourceFiles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedFile: -1,
      viewAs: 'list',
      sorter: {
        type: 'date',
        direction: 'ascending',
      },
    };
  },
  computed: {
    sortedFiles() {
      if (this.sourceFiles) {
        return this.sortFiles(this.sourceFiles);
      }
      return null;
    },
  },
  watch: {
    selectedSource() {
      this.selectedFile = -1;
    },
    sourceFiles() {
      this.selectedFile = -1;
    },
  },
  methods: {
    seeFileDetails(index) {
      const selected = index !== this.selectedFile ? index : -1;
      this.selectedFile = selected;
    },
    changeSortBy(type) {
      if (this.sorter.type === type) {
        this.sorter.direction = this.sorter.direction === 'ascending' ? 'descending' : 'ascending';
      } else {
        this.sorter = {
          type,
          direction: 'ascending',
        };
      }
    },
    sortFiles(fileArr) {
      const copyArr = [...fileArr];
      if (this.sorter.direction === 'ascending') {
        return copyArr.sort((a, b) => this.listSort(a, b));
      } else {
        return copyArr.sort((a, b) => this.listSort(b, a));
      }
    },
    listSort(a, b) {
      switch (this.sorter.type) {
        case 'date':
          return new Date(b.object.createdAt) - new Date(a.object.createdAt);
        case 'name':
          return a.spec.name.localeCompare(b.spec.name);
        case 'kind':
          return a.kind.localeCompare(b.kind);
        case 'status':
          return a.status.state.localeCompare(b.status.state);
        default:
          return new Date(b.object.createdAt) - new Date(a.object.createdAt);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.file-view-actions {
  display: flex;
  gap: 10px
}

.file-container {
  flex: 100%;
  display: flex;
  height: 100%;
}

.selected-item {
  background-color: $bainbridge-blue;
  color: #fff !important
}

.file-view-container {
  display: flex;
  flex-direction: column;
}

.file-view {
  flex: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem 2rem 2rem 1rem;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.file-view-selected {
  margin-right: 1rem;
}

.file-grid {
  padding: 1rem;
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.file-details {
  flex: 40%;
  margin: 2rem 0 2rem 0;
  border-radius: 8px 0 0 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
