import { createApp } from 'vue';

const focus = createApp();

focus.directive('focus', {
  inserted(el) {
    el.focus();
  },
});
export default focus;
