<template>
  <div class="py-0 mapping-row">
    <v-list-item class="px-0">
      <div
        class="w-100 py-0 d-flex flex-row align-start">
        <v-text-field
          ref="group"
          v-model="localGroup"
          :disabled="disabled"
          :rules="[
            v => allGroups.filter(g => g === v).length <= 1 || 'Group already included in mapping',
            v => (
              allowedGroups.length > 0 && v ? allowedGroups.includes(v) : true
            ) || 'Mapped groups do not match allowed groups',
            v => (
              disallowedGroups.length > 0 && v ? !disallowedGroups.includes(v) : true
            ) || 'Mapped groups include disallowed groups',
          ]"
          class="map-column"
          label="Security Group"
          required
          validate-on="blur"
          variant="underlined"
          @blur="emitEdits"/>
        <v-combobox
          ref="roles"
          v-model="localRoles"
          v-model:search="roleQuery"
          :items="sortedRoles"
          :disabled="disabled"
          :rules="[
            v => v.every(r => sortedRoles.includes(r)) || 'One or more values are invalid',
          ]"
          label="Roles"
          class="ml-4 map-column"
          multiple
          chips
          validate-on="blur"
          variant="underlined"
          @update:model-value="roleQuery = ''"
          @blur="emitEdits"/>
      </div>
    </v-list-item>
    <v-dialog
      v-model="confirmDialog"
      max-width="400">
      <v-card>
        <v-card-title
          class="text-h6 dialog-title">
          {{ confirmMessage }}
        </v-card-title>
        <v-card-actions
          class="justify-end">
          <v-btn
            variant="text"
            @click="confirmDialog = false">
            No
          </v-btn>
          <v-btn
            color="primary"
            variant="flat"
            @click="confirmAction">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'MappingRow',
  props: {
    index: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    allGroups: {
      type: Array,
      required: true,
    },
    allowedGroups: {
      type: Array,
      required: true,
    },
    disallowedGroups: {
      type: Array,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      localGroup: '',
      localRoles: [],
      roleQuery: '',
      confirmDialog: false,
      confirmMessage: '',
      confirmAction: '',
    };
  },
  computed: {
    sortedRoles() {
      let results = this.allowedRoles.map((r) => r.display_name);
      results.forEach((result) => {
        if (this.localRoles.includes(result)) {
          const selectedRole = this.allowedRoles.find((r) => r.display_name === result);
          const parentRole = this.allowedRoles.find((r) => r.id === selectedRole.parent);
          const childRoles = this.allowedRoles.filter((r) => r.parent === selectedRole.id);
          const siblingRoles = this.allowedRoles
            .filter((r) => r.parent === selectedRole.parent && r.id !== selectedRole.id);
          if (childRoles.length > 0) {
            results = results.filter((r) => childRoles.every((role) => r !== role.display_name));
          }
          if (siblingRoles.length > 0) {
            results = results.filter((r) => siblingRoles.every((role) => r !== role.display_name));
          }
          if (parentRole) {
            results = results.filter((r) => r !== parentRole.display_name);
          }
        }
      });
      results.sort();
      if (this.roleQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.roleQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.roleQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    ...mapGetters([
      'allowedRoles',
    ]),
  },
  watch: {
    allGroups: {
      handler() {
        this.$refs.group.validate();
      },
      deep: true,
    },
    group: {
      handler() {
        this.localGroup = cloneDeep(this.group);
      },
      deep: true,
      immediate: true,
    },
    roles: {
      handler() {
        this.localRoles = cloneDeep(this.roles);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showDialog(message, action) {
      this.confirmDialog = true;
      this.confirmMessage = message;
      this.confirmAction = action;
    },
    emitEdits() {
      if ((this.localGroup && this.localRoles.length > 0) || (!this.localGroup && this.localRoles.length === 0)) {
        const changes = {
          index: this.index,
          group: this.localGroup.trim(),
          roles: this.localRoles,
        };
        this.$emit('mappingChanged', changes);
      }
    },
  },
};
</script>

<style lang="scss">
.mapping-row {
  .map-column {
    min-width: 45%;
    width: 45%;
    max-width: 45%;
  }
}
</style>
