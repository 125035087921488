import { POST_METHOD, Request } from '@/lib/http';

const baseURL = '/api/sources';

export default {
  getSources() {
    const request = new Request(`${baseURL}/tenant_sources`);
    return request.send();
  },
  getSource(sourceId) {
    const request = new Request(`${baseURL}/tenant_sources/${sourceId}`);
    return request.send();
  },
  runSourceJob(sourceId) {
    const request = new Request(`${baseURL}/tenant_sources/${sourceId}/commands/run`, { method: POST_METHOD });
    return request.send();
  },
};
