<template>
  <div>
    <ContentModal
      :is-visible="showModal"
      :allow-click-away="false"
      size="medium"
      @onModalClose="() => $emit('onModalClose')">
      <template
        #header-content>
        <div>
          Upload Details
        </div>
      </template>
      <template
        #content>
        <div class="upload-content">
          <div class="form-inputs">
            <v-btn
              class="ma-2 back-to-browse"
              height="15"
              @click="backToBrowse">
              <v-icon
                size="20"
                icon="mdi-arrow-left"/>
            </v-btn>
            <v-text-field
              v-model="uploadPackage.fileName"
              label="File Name"
              style="height: 50px; margin-top: 0;"
              variant="outlined"
              density="compact"/>
            <v-select
              v-model="uploadPackage.contentType"
              :items="fileTypes"
              label="Document Type"
              density="compact"
              variant="outlined"/>
          </div>
          <div style="margin:0 2rem 0 2rem">
            <div style="text-align: left; font-size: 14px;">
              {{ progressText }}
            </div>
            <div style="display: flex;">
              <v-progress-linear
                style="margin-top: 10px;"
                :model-value="progressBar"
                :buffer-value="100"
                bg-color="#CAD1D5"
                color="#7FB459"/>
              <v-icon
                :color="progressBar < 100 ? '' : 'secondary'"
                icon="mdi-check-circle"/>
            </div>
            <div style="font-size: 14px;">
              {{ getSizeText() }}
            </div>
            <v-btn
              v-if="uploadPackage.contentType === 'text/csv' || uploadPackage.contentType === 'application/pdf'"
              id="confirm-upload"
              color="primary"
              :disabled="progressBar < 100"
              @click="() => viewModal = true">
              View
            </v-btn>
            <v-btn
              id="confirm-upload"
              color="secondary"
              :disabled="progressBar < 100"
              @click="startFileUpload">
              Upload
            </v-btn>
          </div>
        </div>
      </template>
    </ContentModal>
    <FileViewer
      v-if="uploadPackage.file && viewModal"
      :source-file="uploadPackage.file"
      :is-visible="viewModal"
      @onModalClose="() => viewModal = false"/>
  </div>
</template>

<script>
import ContentModal from '@/components/utils/modal/ContentModal.vue';
import FileViewer from '@/components/utils/viewers/FileViewer.vue';
import { runFileReader, hashAsString } from '@/components/utils/file-upload/upload-utils';

export default {
  name: 'FileUploadPreparer',
  components: {
    ContentModal,
    FileViewer,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    selectedFiles: {
      type: [FileList, Object],
      required: true,
    },
  },
  data() {
    return {
      chunkSize: 2048000,
      batchSize: 50,
      uploadPackage: {
        file: null,
        fileName: '',
        fileHash: '',
        contentType: '',
        sourceBlobRefs: [],
        size: 0,
      },
      fileTypes: [
        'text/csv',
        'application/pdf',
      ],
      progressBar: 0,
      progressText: 'Loading...',
      uploadFileName: '',
      items: [5, 10, 25, 50, 100],
      viewModal: false,
    };
  },
  mounted() {
    this.validateFileUpload();
  },
  methods: {
    backToBrowse() {
      this.$emit('openBrowseUpload');
    },
    async startFileUpload() {
      this.$emit('startFileUpload', this.uploadPackage);
    },
    async validateFileUpload() {
      this.browseModal = false;
      if (this.selectedFiles.length > 0 && this.selectedFiles[0]) {
        [this.uploadPackage.file] = this.selectedFiles;
        this.uploadPackage.fileName = this.uploadPackage.file.name;
        this.uploadPackage.contentType = this.uploadPackage.file.type;
        this.uploadPackage.size = this.uploadPackage.file.size;
        this.editModal = true;
        const chunkFileList = await this.getChunkHashes(this.uploadPackage.file);

        this.validateProgress(60, 'Validating file');

        const fileCheckSum = await this.fileCheckSum(chunkFileList);

        this.uploadPackage = {
          ...this.uploadPackage,
          fileHash: `sha256_${fileCheckSum}`,
          sourceBlobRefs: chunkFileList,
        };

        this.validateProgress(100, 'Ready to upload');
      } else {
        this.validateProgress(0, 'Empty File selected');
      }
    },
    async getChunkHashes(file) {
      const chunkFileList = [];
      const promises = [];
      const chunks = Math.ceil(file.size / this.chunkSize);

      this.validateProgress(10, 'Chunking file');

      for (let currentChunk = 0; currentChunk < chunks; currentChunk++) {
        const start = currentChunk * this.chunkSize;
        const end = ((start + this.chunkSize) >= file.size) ? file.size : start + this.chunkSize;
        const fileSlice = file.slice(start, end);
        promises.push(runFileReader(fileSlice).then((fileChunk) => {
          chunkFileList[currentChunk] = fileChunk.hash;
        }));
      }

      this.validateProgress(40, 'Hashing file');

      await Promise.all(promises);
      return chunkFileList;
    },
    async fileCheckSum(sum) {
      let fileHash = '';

      if (sum.length > 1) {
        const joinedHashes = sum.join('');
        const hashSum = new TextEncoder('utf-8').encode(joinedHashes);
        const buffer = await window.crypto.subtle.digest('SHA-256', hashSum);
        const sumHashString = hashAsString(buffer);
        fileHash = sumHashString;
      } else {
        [fileHash] = sum;
      }

      return fileHash;
    },
    validateProgress(progressBar, progressText) {
      this.progressBar = progressBar;
      this.progressText = progressText;
    },
    getSizeText() {
      let fileSize;
      if (this.uploadPackage.size < 1000000) {
        fileSize = Number.parseFloat((this.uploadPackage.size / 1000).toFixed(2));
        return `File Size: ${fileSize} KB`;
      } else if (this.uploadPackage.size < 1000000000) {
        fileSize = Number.parseFloat((this.uploadPackage.size / 1000000).toFixed(2));
        return `File Size: ${fileSize} MB`;
      } else {
        fileSize = Number.parseFloat((this.uploadPackage.size / 1000000000).toFixed(2));
        return `File Size: ${fileSize} GB`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/colors.scss";

#confirm-upload {
  margin: 15px !important;
  height: 40px;
  width: 120px;
  font-weight: 600;
}

.upload-content {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-inputs {
  margin:0 2rem 0 2rem
}

.back-to-browse {
  display: flex;
  padding: 12px !important;
  margin-bottom: 15px !important;
  min-width: 20px !important;
}

</style>
