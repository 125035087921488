<template>
  <v-list-subheader
    :class="{
      'disabled-filter-label': !isActive,
    }"
    class="filter-label">
    <v-tooltip
      v-if="!isActive"
      location="bottom">
      <template #activator="{ props }">
        <span
          :class="{
            'filter-name': !isOption,
            'option-name': isOption,
          }"
          v-bind="props">
          {{ label }}
          <v-icon
            v-if="!isActive"
            size="small"
            icon="mdi-help"/>
        </span>
      </template>
      <span>
        Filter is set but is inactive for current dashboards.
      </span>
    </v-tooltip>
    <span
      v-else
      :class="{
        'filter-name': !isOption,
        'option-name': isOption,
      }">
      {{ label }}
    </span>
  </v-list-subheader>
</template>

<script>
export default {
  name: 'FilterLabel',
  props: {
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isOption: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
