<template>
  <v-container>
    <div>
      <v-card class="pa-6">
        <LibrarySearchBar :search="search"/>
        <v-progress-circular
          v-if="loading"
          :size="70"
          class="text-primary loader"
          indeterminate/>
        <v-list
          v-else
          lines="two">
          <v-list-item
            v-for="drugName in sortedLibrary"
            :key="drugName"
            class="drug">
            <div class="drug-title">
              {{ format(drugName) }}
            </div>
            <v-list-item-action>
              <div v-if="filtered[drugName].length === 1">
                <v-btn
                  icon
                  @click="goToDrug(drugName)">
                  <v-icon
                    class="text-grey-lighten-1"
                    icon="mdi-trending-neutral"/>
                </v-btn>
              </div>
              <div v-else>
                <v-menu
                  :content-class="'sub-drug-menu-container'">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon>
                      <v-icon
                        class="text-grey-lighten-1"
                        icon="mdi-table-of-contents"/>
                    </v-btn>
                  </template>
                  <div :id="`${drugName.toLowerCase().replace(/ /g, '')}-sub-drug-menu`">
                    <v-list v-if="intermittent(drugName)">
                      <p class="pl-4 my-2 text-primary-lighten-2">
                        Intermittent
                      </p>
                      <span v-if="drugName">
                        <div
                          v-for="subDrug in intermittent(drugName)"
                          :key="`int: ${drugName}-${subDrug.result}`">
                          <v-list-item
                            avatar
                            ripple
                            @click="goToDrug(drugName, subDrug.result)">
                            <v-list-item-title class="pl-3 sub-drug">
                              {{ subDrug.result }}
                            </v-list-item-title>
                          </v-list-item>
                        </div>
                      </span>
                    </v-list>
                    <v-list v-if="continuous(drugName)">
                      <p
                        :class="{'mt-2': !intermittent(drugName)}"
                        class="pl-4 mb-2 text-primary-lighten-2">
                        Continuous
                      </p>
                      <span v-if="drugName">
                        <div
                          v-for="subDrug in continuous(drugName)"
                          :key="`cont: ${drugName}-${subDrug.result}`">
                          <v-list-item
                            avatar
                            ripple
                            @click="goToDrug(drugName, subDrug.result)">
                            <v-list-item-title class="pl-3">
                              {{ subDrug.result }}
                            </v-list-item-title>
                          </v-list-item>
                        </div>
                      </span>
                    </v-list>
                  </div>
                </v-menu>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import StringUtils from '@/lib/string';
import LibrarySearchBar from './LibrarySearchBar.vue';

export default {
  name: 'DrugList',
  components: {
    LibrarySearchBar,
  },
  data() {
    return {
      library: {},
      filtered: {},
      loading: true,
    };
  },
  computed: {
    sortedLibrary() {
      return Object.keys(this.filtered).sort();
    },
    ...mapGetters([
      'tenant',
    ]),
  },
  watch: {
    tenant() {
      if (this.tenant) {
        this.fetchLibrary();
      }
    },
  },
  created() {
    this.fetchLibrary();
  },
  methods: {
    fetchLibrary() {
      this.loading = true;
      this.$services.library.getLibrary().then((json) => {
        this.library = json.items;
        this.filtered = this.library;
      }).finally(() => {
        this.loading = false;
      });
    },
    format(drugName) {
      return StringUtils.capitalize(drugName);
    },
    goToDrug(drugName, subName) {
      const navigateTo = {
        name: 'DrugInfo',
        params: {
          drug: encodeURIComponent(drugName),
        },
      };
      if (subName) {
        navigateTo.query = {
          sub: encodeURIComponent(subName),
        };
      }
      this.$router.push(navigateTo);
    },
    intermittent(drugName) {
      const intItems = this.filtered[drugName].filter((sub) => sub.type === 'Intermittent');
      return intItems.length > 0 ? intItems : false;
    },
    continuous(drugName) {
      const contItems = this.filtered[drugName].filter((sub) => sub.type === 'Continuous');
      return contItems.length > 0 ? contItems : false;
    },
    search(query) {
      const list = {};
      (Object.keys(this.library)).sort().forEach((drug) => {
        if (query !== '') {
          if (!this.checkFilterSearch(drug, query.toLowerCase())) {
            return;
          }
        }

        list[drug] = this.library[drug];
      });
      this.filtered = list;
    },
    checkFilterSearch(drug, query) {
      const subWords = drug.split(' ');
      let match = false;

      subWords.forEach((word) => {
        if (word.toLowerCase().substring(0, query.length) === query) {
          match = true;
        }
      });
      return match;
    },
  },
};
</script>

<style lang="scss">
.drug-title {
  font-size: 16px;
}
.sub-drug-menu-container {
  min-width: 200px !important;
}
</style>
