// filter-set-collection.js
// Filter set collection class that defines properties and methods for a collection of filter sets.

import moment from 'moment';
import { FilterSet } from '@/lib/filter-set';
import { UserFilterSet } from '@/lib/user-filter-set';
import store from '@/store';

export class FilterSetCollection {
  constructor(objCollection) {
    // Set props for each passed object's keys if key's value is a FilterSet or UserFilterSet class
    if (objCollection) {
      Object.keys(objCollection).forEach((objId) => {
        if (objCollection[objId] instanceof FilterSet || objCollection[objId] instanceof UserFilterSet) {
          this[objId] = objCollection[objId];
        }
      });
    }
  }

  // Get ids for filter sets in filter set collection
  get filterIds() {
    return Object.keys(this);
  }

  get filterSetsCapableOf() {
    const filterSetsCapableOf = new FilterSetCollection();
    const userCapabilities = store.getters.capabilities;
    this.filterIds.forEach((filterSetId) => {
      const isCapable = this[filterSetId].filters.filterIds.every(
        (filterId) => this[filterSetId].filters[filterId].capabilities.some((cap) => userCapabilities.includes(cap)),
      );
      if (isCapable) {
        filterSetsCapableOf[filterSetId] = this[filterSetId];
      }
    });
    return filterSetsCapableOf;
  }

  // Determine if filter set collection contains any filter sets or not
  get isEmpty() {
    return this.filterIds.length === 0;
  }

  // Get filter set collection's filter set ids sorted by filter set created time
  get sortedFilterIds() {
    return this.filterIds.sort((a, b) => moment(this[b].created).diff(moment(this[a].created)));
  }
}

export default {
  FilterSetCollection,
};
