<template>
  <div>
    <CsvViewer
      v-if="sourceFile.type === 'text/csv'"
      :is-visible="isVisible"
      :selected-file="sourceFile"
      @onModalClose="() => $emit('onModalClose')"/>
    <PdfViewer
      v-if="sourceFile.type === 'application/pdf'"
      :is-visible="isVisible"
      :source="sourceFile"
      @onModalClose="() => $emit('onModalClose')"/>
  </div>
</template>

<script>
import PdfViewer from '@/components/utils/viewers/pdf-viewer/PdfViewer.vue';
import CsvViewer from '@/components/utils/viewers/csv-viewer/CsvViewer.vue';

export default {
  name: 'FileViewer',
  components: {
    PdfViewer,
    CsvViewer,
  },
  props: {
    sourceFile: {
      type: File,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
