<template>
  <div
    :class="`select-filter ${formattedLabel}-row`">
    <div class="filter-label">
      <FilterLabel
        :label="label"
        :is-active="isActive"
        :is-option="true"/>
    </div>
    <div>
      <v-select
        ref="selectFilter"
        v-model="value"
        variant="underlined"
        :rules="valueRequired ? [v => v.length > 0 || 'Required'] : []"
        :items="possibleValues"
        :menu-props="{ 'contentClass': `${formattedLabel}-select` }"
        :disabled="!isActive"/>
    </div>
    <FilterActions
      :is-active="isActive"
      @removeFilter="removeFilter"/>
  </div>
</template>

<script>

import FilterLabel from './FilterLabel.vue';
import FilterActions from './FilterActions.vue';

export default {
  name: 'SelectFilter',
  components: {
    FilterLabel,
    FilterActions,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    valueRequired: {
      type: Boolean,
      default: () => false,
    },
    passedValue: {
      type: Object,
      default: () => {},
    },
    defaultValue: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    possibleValues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    formattedLabel() {
      return this.label.toLowerCase().replace(/ /g, '-');
    },
  },
  watch: {
    passedValue: {
      handler() {
        this.value = this.passedValue.value ? this.passedValue.value : this.defaultValue.value;
      },
      deep: true,
      immediate: true,
    },
    value() {
      this.$refs.selectFilter.validate();
      if ((this.passedValue.value && this.value !== this.passedValue.value) || this.value !== this.defaultValue.value) {
        this.$emit('valueChanged', {
          key: this.filterId,
          value: { value: this.value },
        });
      }
    },
  },
  mounted() {
    this.$refs.selectFilter.validate();
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter', this.filterId);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-filter {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 2fr;
  gap: 1rem;
}
</style>
