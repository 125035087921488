export default {
  state: {
    snack: {
      text: null,
      show: false,
    },
  },
  mutations: {
    UPDATE_SNACK(state, snack) {
      state.snack = snack;
    },
  },
  actions: {
    createSnack({ commit }, message) {
      const snack = {
        text: message,
        show: true,
      };
      commit('UPDATE_SNACK', snack);
    },
    resetSnack({ commit }) {
      const snack = {
        text: null,
        show: false,
      };
      commit('UPDATE_SNACK', snack);
    },
  },
  getters: {
    snack: (state) => state.snack,
  },
};
