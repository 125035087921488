<template>
  <v-container
    class="user-admin"
    :class="mainPage ? 'user-admin-flex' : ''">
    <v-breadcrumbs
      v-if="!mainPage"
      :items="navItems"
      class="user-admin-breadcrumbs w-100 mt-2">
      <template #divider>
        <v-icon
          class="breadcrumb-chevron"
          icon="mdi-chevron-right"/>
      </template>
      <template #item="props">
        <v-breadcrumbs-item
          :key="props.item.name"
          :href="props.item.link"
          :target="'_self'"
          :disabled="props.item.isCurrent">
          {{ props.item.name }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="clearfix"/>
    <router-view/>
  </v-container>
</template>

<script>
export default {
  name: 'UserAdmin',
  computed: {
    mainPage() {
      return this.$route.name === 'UserPortal';
    },
    navItems() {
      const navItems = [
        {
          name: 'User Administration Portal',
          link: '#/user-admin',
        },
      ];
      switch (this.$route.name) {
        case 'SsoConfig':
          navItems.push({
            name: 'Single Sign-On Configuration',
            link: '#/user-admin/sso-config',
            isCurrent: true,
          });
          break;
        case 'UserConfig':
          navItems.push({
            name: 'User Configuration',
            link: '#/user-admin/user-config',
            isCurrent: true,
          });
          break;
        default:
          navItems[0].isCurrent = true;
      }
      return navItems;
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/colors.scss';

.breadcrumb-chevron {
  color: rgba(0, 0, 0, .54);;
  font-size: 16px;
}

.user-admin {
  .breadcrumbs, .user-admin-breadcrumbs {
    display: block;
    float: left;
    text-align: left;
    color: $bainbridge-blue;
    font-size: 14px;
    li {
      display: inline-block;
      &:last-child {
        a {
          color: $bainbridge-green-dark;
          opacity: 1 !important;
        }
      }
    }
  }
}
.user-admin-flex {
  display: flex;
}
</style>
