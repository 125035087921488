import { Request } from '@/lib/http';
import store from '@/store';

const baseURL = '/api/im';

export default {
  getMenus() {
    const tenantId = store.getters.tenant.id;
    const request = new Request(`${baseURL}/menus/${tenantId}`);
    return request.send();
  },
};
