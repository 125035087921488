import { Request } from '@/lib/http';

const baseURL = '/api/config';
const CONFIG_KEY = 'config';
const CONFIG_LAST_SET_KEY = 'configLastSet';

export default {
  getConfig() {
    let localConfig = localStorage.getItem(CONFIG_KEY);
    let localConfigLastSet = localStorage.getItem(CONFIG_LAST_SET_KEY);
    const now = Math.round(new Date().getTime() / 1000);

    try {
      localConfig = JSON.parse(localConfig);
      localConfigLastSet = JSON.parse(localConfigLastSet);
    } catch (error) {
      localConfig = null;
      localConfigLastSet = null;
    }

    if (localConfig && localConfigLastSet && (localConfigLastSet + localConfig.ttl) > now) {
      const promise = new Promise((resolve, reject) => {
        resolve(localConfig.config);
      });
      return promise;
    } else {
      const request = new Request(`${baseURL}/`, { withoutAuth: true, withoutClaims: true });
      return request.send().then((json) => {
        localStorage.setItem(CONFIG_KEY, JSON.stringify(json));
        localStorage.setItem(CONFIG_LAST_SET_KEY, JSON.stringify(now));
        return json.config;
      });
    }
  },
  clearConfig() {
    localStorage.removeItem(CONFIG_KEY);
    localStorage.removeItem(CONFIG_LAST_SET_KEY);
  },
};
