<template>
  <div
    :class="`boolean-filter ${formattedLabel}-row`">
    <div class="filter-label">
      <FilterLabel
        :label="label"
        :is-active="isActive"/>
    </div>
    <div>
      <v-select
        v-model="value"
        :items="possibleStates"
        :menu-props="{ 'contentClass': `${formattedLabel}-select` }"
        :disabled="!isActive"/>
    </div>
    <FilterActions
      :is-active="isActive"
      @removeFilter="removeFilter"/>
  </div>
</template>

<script>

import FilterLabel from './FilterLabel.vue';
import FilterActions from './FilterActions.vue';

const possibleStates = [
  { title: 'Any', value: 'Any' },
  { title: 'Yes', value: 'Yes' },
  { title: 'No', value: 'No' },
];

const DEFAULT_VALUE = { value: possibleStates[0].value };

export default {
  name: 'BooleanFilter',
  components: {
    FilterLabel,
    FilterActions,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    passedValue: {
      type: Object,
      default: () => DEFAULT_VALUE,
    },
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      value: DEFAULT_VALUE.value,
      possibleStates,
    };
  },
  computed: {
    formattedLabel() {
      return this.label.toLowerCase().replace(/ /g, '-');
    },
  },
  watch: {
    passedValue: {
      handler() {
        this.value = this.passedValue.value ? this.passedValue.value : DEFAULT_VALUE.value;
      },
      deep: true,
      immediate: true,
    },
    value() {
      const value = this.value === DEFAULT_VALUE.value ? null : this.value;
      if ((this.passedValue.value && this.value !== this.passedValue.value) || this.value !== DEFAULT_VALUE.value) {
        this.$emit('valueChanged', {
          key: this.filterId,
          value: { value },
        });
      }
    },
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter', this.filterId);
    },
  },
};
</script>

<style lang="scss" scoped>
.boolean-filter {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 2fr;
  gap: 1rem;
}
</style>
