import {
  POST_METHOD, PUT_METHOD, DELETE_METHOD, Request,
} from '@/lib/http';

const baseURL = '/api/users';

export default {
  getTenants() {
    const request = new Request(`${baseURL}/tenants`);
    return request.send();
  },
  getTenantAllowedRoles(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/allowed_roles`);
    return request.send();
  },
  getTenantMappedRoles(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/mapped_roles`);
    return request.send();
  },
  getTenantRoles(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/roles`);
    return request.send();
  },
  getTenantSecurityGroups(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/security_groups`);
    return request.send();
  },
  getTenantUsers(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/users`);
    return request.send();
  },
  getTrackToken(userId) {
    const request = new Request(`${baseURL}/admin/user/${userId}/track_token`);
    return request.send();
  },
  getUsers() {
    const request = new Request(`${baseURL}/`);
    return request.send();
  },
  postCapability(capabilityData) {
    const request = new Request(`${baseURL}/commands/toggleCapability`, {
      body: capabilityData, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postChangePassword(userId, passwordData) {
    const request = new Request(`${baseURL}/${userId}/commands/changePassword`, {
      body: passwordData, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postDefaultUserTenant(userId, tenantId) {
    const request = new Request(`${baseURL}/${userId}/default_tenant`, {
      body: tenantId, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postForgotPassword(forgotData) {
    const request = new Request(`${baseURL}/commands/requestPasswordReset`, {
      body: forgotData, method: POST_METHOD, withoutAuth: true, withoutClaims: true,
    });
    return request.send();
  },
  postResetPassword(resetPasswordData) {
    const request = new Request(`${baseURL}/commands/resetPassword`, {
      body: resetPasswordData, method: POST_METHOD, withoutAuth: true, withoutClaims: true,
    });
    return request.send();
  },
  postTenant(tenantInfo) {
    const request = new Request(`${baseURL}/tenants`, {
      body: tenantInfo, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postTenantRoleMapping(tenantId, mapping) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/mapped_roles`, {
      body: mapping, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postTenantSecurityGroups(tenantId, groups) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/security_groups`, {
      body: groups, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postTrackTokenRead(userId, tokenData) {
    const request = new Request(`${baseURL}/${userId}/commands/readTrackToken`, {
      body: tokenData, method: POST_METHOD,
    });
    return request.send();
  },
  postTrackingLog(trackingData) {
    const request = new Request('/track', {
      body: trackingData, method: POST_METHOD, withoutAuth: true, withoutClaims: true, preventAbort: true,
    });
    return request.send();
  },
  postUser(userInfo) {
    const request = new Request(`${baseURL}/admin/user`, {
      body: userInfo, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postUserMfa(userId, mfaInfo) {
    const request = new Request(`${baseURL}/${userId}/commands/updateMfaStatus`, {
      body: mfaInfo, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  putDefaultUserTenant(userId, tenantId) {
    const request = new Request(`${baseURL}/${userId}/default_tenant`, {
      body: tenantId, method: PUT_METHOD, preventAbort: true,
    });
    return request.send();
  },
  putTenantRoleMapping(tenantId, mapping) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/mapped_roles`, {
      body: mapping, method: PUT_METHOD, preventAbort: true,
    });
    return request.send();
  },
  putTenantSecurityGroups(tenantId, groups) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/security_groups`, {
      body: groups, method: PUT_METHOD, preventAbort: true,
    });
    return request.send();
  },
  putUser(userInfo) {
    const request = new Request(`${baseURL}/admin/user/${userInfo.id}`, {
      body: userInfo, method: PUT_METHOD, preventAbort: true,
    });
    return request.send();
  },
  deleteDefaultUserTenant(userId) {
    const request = new Request(`${baseURL}/${userId}/default_tenant`, {
      method: DELETE_METHOD, preventAbort: true,
    });
    return request.send();
  },
  deleteTenantRoleMapping(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/mapped_roles`, {
      method: DELETE_METHOD, preventAbort: true,
    });
    return request.send();
  },
  deleteTenantSecurityGroups(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/security_groups`, {
      method: DELETE_METHOD, preventAbort: true,
    });
    return request.send();
  },
};
