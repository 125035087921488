<template>
  <div id="enter-email-form">
    <p class="text-left">
      Please enter the e-mail address associated with your Bainbridge Health account.
    </p>
    <div class="mt-5">
      <v-text-field
        v-model="identity"
        name="email"
        color="primary"
        label="Email"
        variant="underlined"
        autofocus
        @blur="(event) => {
          if (securityScan) {
            submit();
          }
        }"
        @keyup="(event) => {
          if (event.key === 'Enter' && !securityScan) {
            submit();
          }
        }"/>
    </div>
    <div v-if="!securityScan">
      <v-btn
        :disabled="!identity || identity.length === 0 || !!localLoading"
        :loading="localLoading"
        color="secondary"
        class="float-right next text-white"
        variant="flat"
        @click="submit">
        Next
      </v-btn>
      <div
        v-if="identity && identity.length > 0 && !localLoading"
        id="next-active"
        style="display: none">
        <!-- this exists for pingdom purposes -->
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { EMAIL_PASSWORD_LOGIN_METHOD, INTERNAL_IDENTITY_PROVIDER, getLoginMethod } from '@/lib/identity-methods';
import { EventLog } from '@/lib/event-log';

export default {
  name: 'EnterEmail',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    passedIdentity: {
      type: String,
      default: () => null,
    },
    securityScan: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      localLoading: null,
      identity: this.passedIdentity,
    };
  },
  watch: {
    loading: {
      handler() {
        this.localLoading = cloneDeep(this.loading);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submit() {
      if (this.identity) {
        this.localLoading = true;
        this.$services.identity.getUserProvider(this.identity).then((result) => {
          const provider = result.provider || INTERNAL_IDENTITY_PROVIDER;
          const identityData = {
            email: this.identity,
            tenant: result.tenant,
            provider,
            cred_expires: result.cred_expires,
          };
          const loggingData = new EventLog({
            event: 'sign_in.enter_email',
            email: this.identity,
          });
          this.$services.users.postTrackingLog(loggingData);
          this.localLoading = false;
          if (provider !== INTERNAL_IDENTITY_PROVIDER) {
            localStorage.setItem('signinEmail', this.identity);
          }
          this.$emit(getLoginMethod(this.identity, provider), identityData);
        }).catch((error) => {
          const identityData = {
            email: this.identity,
            tenant: null,
            provider: INTERNAL_IDENTITY_PROVIDER,
            error,
          };
          this.$emit(EMAIL_PASSWORD_LOGIN_METHOD, identityData);
          this.localLoading = false;
        });
      }
    },
  },
};
</script>
