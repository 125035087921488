import identity from 'firebase/app';
import '@mdi/font/css/materialdesignicons.css';
import { createApp } from 'vue';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { v1 as uuidv1 } from 'uuid';

import detectBrowser from 'vue-detect-browser';
import { EventLog } from '@/lib/event-log';
import { INTERNAL_IDENTITY_METHOD } from '@/lib/identity-methods';
import theme from './lib/color-theme';
import './sass/icons.scss'; // Icons are loaded separately due to relative namespacing.
import './sass/main.scss';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import Services from './services/index';
import './directives/focus';

import './lib/remove-polyfill';
import './lib/url-polyfill';

let app;

const queryParams = new URLSearchParams(window.location.search);
const TRACK_ENABLED = queryParams.has('track');
const DEBUG_ENABLED = VUE_DEBUG_ENABLED || TRACK_ENABLED;

// Currently these configurations are having issues with the new setup
// will come back to them after getting the migration taken care of
// if (DEBUG_ENABLED) {
//   /* eslint-disable no-console */
//   console.info('debug: enabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
//   /* eslint-enable no-console */

//   app.config.devtools = true;
//   app.config.debug = true;
//   app.config.silent = false;
// } else {
//   /* eslint-disable no-console */
//   console.info('debug: disabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
//   /* eslint-enable no-console */

//   app.config.devtools = false;
//   app.config.debug = false;
//   app.config.silent = true;
// }
const vuetify = createVuetify({
  theme: theme.theme,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});
const initializeVue = (message) => {
  if (!app) {
    app = createApp(App);

    if (DEBUG_ENABLED) {
      /* eslint-disable no-console */
      console.info('debug: enabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
      /* eslint-enable no-console */
      app.config.devtools = true;
      app.config.debug = true;
      app.config.silent = false;
    } else {
      /* eslint-disable no-console */
      console.info('debug: disabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
      /* eslint-enable no-console */
      app.config.devtools = false;
      app.config.debug = false;
      app.config.silent = true;
    }

    app.use(detectBrowser);
    app.use(vuetify);
    app.use(Services);
    app.use(router);
    app.use(store);
    Object.defineProperty(app.config.globalProperties, '$notify', {
      get() {
        return (msg) => {
          app.config.globalProperties.$store.dispatch('createSnack', msg);
        };
      },
    });
    router.isReady().then(() => {
      app.mount('#app');
    });
    // app.mount('#app');
    if (DEBUG_ENABLED) {
      window.App = app;

      /* eslint no-console: ["error", { allow: ["error"] }] */
      console.error('cf. window.App');
    }
  }
  if (message) {
    app.$notify(message);
  }
};

// Set session ID to track user's current app session
sessionStorage.setItem('track_id', uuidv1());

Services.config.getConfig().then((config) => {
  // Initialize Google Identity Platform
  identity.initializeApp(config.identity);
  initializeVue();

  // Detect redirect results from SSO, as well as users who are already logged in
  identity.auth().onIdTokenChanged((userInfo) => {
    if (userInfo && userInfo._lat) {
      store.dispatch('setAccessToken', userInfo._lat);
      if (!localStorage.getItem('claimsToken') && !store.getters.user) {
        const ssoUser = userInfo.providerData && userInfo.providerData.length > 0 &&
          userInfo.providerData[0].providerId !== INTERNAL_IDENTITY_METHOD;
        if (ssoUser) {
          let accountMatch = true;
          const identityEmail = userInfo.email;
          const signinEmail = localStorage.getItem('signinEmail');
          if (signinEmail) {
            accountMatch = identityEmail && signinEmail.toLowerCase() === identityEmail.toLowerCase();
            if (!accountMatch) {
              store.dispatch('logout').then(() => {
                app.$notify('Account does not match the email address initially provided.');
                const failLog = new EventLog({
                  event: 'account.fail_login',
                  email: signinEmail,
                  error: 'account_mismatch',
                });
                Services.users.postTrackingLog(failLog);
              });
            }
          }
          if (!signinEmail || accountMatch) {
            store.dispatch('login').catch((error) => {
              store.dispatch('logout').then(() => {
                app.$notify('Login failed.');
                const failLog = new EventLog({
                  event: 'account.fail_login',
                  email: userInfo.email,
                  error: error.message,
                });
                Services.users.postTrackingLog(failLog);
              });
            });
          }
        } else {
          store.dispatch('setLoginComplete', true);
        }
      } else if (!store.getters.user) {
        store.dispatch('setLoginComplete', true);
      }
    } else {
      store.dispatch('setLoginComplete', true);
    }
  });

  store.dispatch('setTenantConfig', config.tenant);

  // Disabled until I can get around to getting this portion to work
  // if (SENTRY_ENABLED) {
  //   Sentry.init({
  //     dsn: config.dsn,
  //     environment: config.environment,
  //     integrations: [new VueIntegration({ Vue, logErrors: true })],
  //   });
  //   Sentry.setTag('track_id', sessionStorage.getItem('track_id'));
  // }
});
