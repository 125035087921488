// http.js
// Basic wrapper around fetch that uses Promises
// and automatically handles headers/cookies/errors/etc.

import fetch from 'isomorphic-fetch';
import * as Sentry from '@sentry/browser';
import { Promise } from 'rsvp';
import { v1 as uuidv1 } from 'uuid';
import store from '@/store/index';

export const fallbackErrorMessage = 'An unknown error has occurred';
export const LOGGED_OUT_MESSAGE = 'Your session has expired.';
export const NO_TENANTS_MESSAGE = 'You have no tenants associated with this user.';

export const DELETE_METHOD = 'DELETE';
export const GET_METHOD = 'GET';
export const PATCH_METHOD = 'PATCH';
export const POST_METHOD = 'POST';
export const PUT_METHOD = 'PUT';

export class Request {
  constructor(endpoint, obj) {
    const options = obj || {};
    const {
      body, isFetchRetry, isNetworkRetry, method, preventAbort, raw, withoutAuth, withoutClaims,
    } = options;

    this.endpoint = endpoint;
    this.id = uuidv1();
    this.init = {
      method: method || GET_METHOD,
      credentials: 'include',
      headers: {},
    };
    if (body) {
      this.init.body = JSON.stringify(body);
    }
    if ([PATCH_METHOD, POST_METHOD, PUT_METHOD].includes(method) && body) {
      const contentHeader = {
        'Content-Type': 'application/json',
      };
      this.init.headers = { ...this.init.headers, ...contentHeader };
    }
    if (!withoutAuth) {
      const authHeader = {
        Authorization: `Bearer ${store.getters.accessToken}`,
      };
      this.init.headers = { ...this.init.headers, ...authHeader };
    }
    if (!withoutClaims) {
      const claimsHeader = {
        Claims: store.getters.claimsToken,
      };
      this.init.headers = { ...this.init.headers, ...claimsHeader };
    }
    this.isFetchRetry = isFetchRetry || false;
    this.isNetworkRetry = isNetworkRetry || false;
    this.preventAbort = preventAbort || false;
    this.raw = raw || false;

    store.dispatch('setRequest', this);
  }

  handleFetch(retryCount) {
    let init = { ...this.init };
    if (retryCount > -1) {
      const controller = new AbortController();
      setTimeout(() => {
        controller.abort();
      }, 30000);
      init = {
        ...init,
        signal: controller.signal,
      };
    }
    fetch(this.endpoint, init).then((response) => {
      if (!response.ok) {
        if ([401, 403].includes(response.status) && !this.isFetchRetry) {
          store.dispatch('setRequestValue', { id: this.id, key: 'isFetchRetry', value: true });
          store.dispatch('resetIdentityTokens');
        } else if (!this.isFetchRetry) {
          store.dispatch('setRequestValue', { id: this.id, key: 'isFetchRetry', value: true });
          this.handleFetch();
        } else {
          response.json().then((json) => {
            this.reject(json.error || fallbackErrorMessage);
          }).catch(() => {
            this.reject(fallbackErrorMessage);
          }).finally(() => {
            store.dispatch('removeRequest', this.id);
          });
        }
        return { then: () => {} };
      }
      store.dispatch('removeRequest', this.id);
      if (this.isNetworkRetry) {
        store.dispatch('resendRequests');
      }
      return this.raw ? response : response.json();
    }).then((json) => {
      this.resolve(json);
    }).catch((error) => {
      // AbortError comes from AbortController which will attempt to
      // retry requests that get stuck in pending
      if (error.name === 'AbortError') {
        if (retryCount > 0) {
          // eslint-disable-next-line no-console
          console.log('Retry attempts left', retryCount - 1);
          this.handleFetch(retryCount - 1);
        } else {
          this.reject('Too many retry attempts');
        }
      }
      if (error && error.message && !error.message.includes('abort')) {
        const isNetworkError = error.message.toLowerCase().includes('failed to fetch') ||
          error.message.toLowerCase().includes('network');
        if (!this.isNetworkRetry) {
          if (isNetworkError) {
            store.dispatch('abortRequests', true);
          } else {
            Sentry.captureException(error);
            this.reject(fallbackErrorMessage);
            store.dispatch('removeRequest', this.id);
          }
        } else {
          Sentry.captureException(error);
          if (isNetworkError) {
            this.reject('Failed to connect. Please check your internet connection.');
          } else {
            this.reject(fallbackErrorMessage);
          }
          store.dispatch('removeRequest', this.id);
        }
      }
    });
  }

  sendWithRetry() {
    return new Promise((resolve, reject) => {
      store.dispatch('setRequestValue', { id: this.id, key: 'resolve', value: resolve });
      store.dispatch('setRequestValue', { id: this.id, key: 'reject', value: reject });
      this.handleFetch(3);
    });
  }

  send() {
    return new Promise((resolve, reject) => {
      store.dispatch('setRequestValue', { id: this.id, key: 'resolve', value: resolve });
      store.dispatch('setRequestValue', { id: this.id, key: 'reject', value: reject });
      this.handleFetch();
    });
  }
}

export function generateQueryParamsString(queryParams) {
  let queryParamsString = '';
  if (!queryParams) return queryParamsString;
  if (Object.keys(queryParams).length > 0) {
    const queryKeys = Object.keys(queryParams);
    for (let i = 0; i < queryKeys.length; i++) {
      const queryKey = queryKeys[i];
      const queryParamString = `${encodeURIComponent(queryKey)}=${encodeURIComponent(JSON.stringify(queryParams[queryKey]))}`;
      if (i === 0) {
        queryParamsString += '?';
      }
      queryParamsString += queryParamString;
      // If not the last item
      if (i < queryKeys.length - 1) {
        queryParamsString += '&';
      }
    }
  }
  return queryParamsString;
}

export default {
  generateQueryParamsString,
  DELETE_METHOD,
  GET_METHOD,
  PATCH_METHOD,
  POST_METHOD,
  PUT_METHOD,
  LOGGED_OUT_MESSAGE,
  NO_TENANTS_MESSAGE,
};
