<template>
  <v-menu>
    <template #activator="{ props }">
      <v-icon
        v-bind="props"
        class="file-view-as-icon"
        size="30"
        :icon="viewAsFieldsIcons[viewState]"/>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in viewStateFields"
        ref="viewStateList"
        :key="index"
        :value="item.value"
        @click="() => emitViewState(item.value)">
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ViewStateMenu',
  props: {
    viewAs: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      viewState: '',
      viewStateFields: [
        { text: 'as list', value: 'list' },
        { text: 'as small icons', value: 'iconsSmall' },
        { text: 'as large icons', value: 'iconsLarge' },
      ],
      viewAsFieldsIcons: {
        list: 'mdi-format-list-bulleted',
        iconsSmall: 'mdi-apps',
        iconsLarge: 'mdi-view-module',
      },
    };
  },
  watch: {
    viewAs() {
      if (this.viewState !== this.viewAs) this.viewState = this.viewAs;
    },
  },
  mounted() {
    this.viewState = this.viewAs;
  },
  methods: {
    emitViewState(viewState) {
      this.$emit('view-state', viewState);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

  .file-view-as-icon {
    color: $bainbridge-blue !important;
  }
</style>
