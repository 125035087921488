import IntelligenceService from '@/services/intelligence';

export default {
  state: {
    intelligenceIsDirty: false,
    reports: [],
    reportsState: null,
  },
  mutations: {
    RESET_REPORTS(state) {
      state.intelligenceIsDirty = false;
      state.reports = [];
      state.reportsState = null;
    },
    SET_INTELLIGENCE_IS_DIRTY(state, status) {
      state.intelligenceIsDirty = status;
    },
    SET_REPORTS(state, reports) {
      state.reports = reports;
    },
    SET_REPORTS_STATE(state, reportsState) {
      state.reportsState = reportsState;
    },
  },
  actions: {
    resetReports({ commit }) {
      commit('RESET_REPORTS');
    },
    setIntelligenceIsDirty({ commit }, status) {
      commit('SET_INTELLIGENCE_IS_DIRTY', status);
    },
    setReports({ dispatch, commit }, tenant) {
      IntelligenceService.getIntelligenceReports(tenant.id).then((reports) => {
        commit('SET_REPORTS', reports.reports);
        dispatch('setReportsState', 'loaded');
      }).catch(() => {
        dispatch('setReportsState', 'error');
      });
    },
    setReportsState({ commit }, reportsState) {
      commit('SET_REPORTS_STATE', reportsState);
    },
  },
  getters: {
    intelligenceIsDirty: (state) => state.intelligenceIsDirty,
    reports: (state) => state.reports,
    reportsState: (state) => state.reportsState,
  },
};
