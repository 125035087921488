<template>
  <div class="mt-12 password-reset-container">
    <v-card class="mt-6">
      <v-card-title class="bg-primary text-white">
        <span>Reset Password</span>
        <v-spacer/>
      </v-card-title>
      <v-card-text>
        <p class="text-left pt-2">
          Your new password must be at least 8 characters and contain an
          uppercase letter, a lowercase letter, and a number.
        </p>
        <div class="mt-6">
          <v-text-field
            v-model="newPassword"
            :rules="[() => validation()]"
            class="reset__newpassword"
            name="newPassword"
            label="New Password"
            min="8"
            autofocus
            type="password"/>
        </div>
        <div>
          <v-text-field
            v-model="confirmPassword"
            :rules="[() => matchTest()]"
            class="mb-0 reset__confirmpassword"
            name="confirmPassword"
            label="Confirm Password"
            min="8"
            type="password"/>
        </div>
        <v-btn
          :disabled="disabled || resetLoading"
          color="secondary"
          class="float-right text-white"
          name="submitReset"
          @click="submitReset">
          Submit
        </v-btn>
        <div class="clearfix"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventLog } from '@/lib/event-log';

export default {
  name: 'PasswordReset',
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      responseBody: null,
      errors: [],
      resetLoading: false,
    };
  },
  computed: {
    disabled() {
      return !(
        this.newPassword &&
        this.newPassword === this.confirmPassword &&
        this.newPassword.length >= 8 &&
        this.passwordTest()
      );
    },
    ...mapGetters([
      'user',
    ]),
  },
  created() {
    if (this.user) {
      this.$router.push({ name: 'Summary' });
    }
  },
  methods: {
    submitReset() {
      this.errors = [];
      if (this.passwordTest()) {
        const passwordsObject = {
          token: this.$route.query.token,
          password: this.newPassword,
        };

        const attemptLog = new EventLog({
          event: 'account.password.attempt_reset',
        });
        this.$services.users.postTrackingLog(attemptLog);

        this.resetLoading = true;
        this.$services.users.postResetPassword(passwordsObject).then((json) => {
          this.resetLoading = false;
          if (json.success) {
            this.$notify('Your password has successfully been reset.');
            const successLog = new EventLog({
              event: 'account.password.reset',
            });
            this.$services.users.postTrackingLog(successLog);
            this.$router.push('/signin');
          } else {
            this.$notify('Failed to reset your password.');
            const failLog = new EventLog({
              event: 'account.password.fail_reset',
            });
            this.$services.users.postTrackingLog(failLog);
          }
        }).catch((error) => {
          this.$notify('Failed to reset your password.');
          const errorLog = new EventLog({
            event: 'account.password.fail_reset',
            error: error.message,
          });
          this.$services.users.postTrackingLog(errorLog);
        });
      }
    },
    matchTest() {
      if (
        this.confirmPassword &&
        this.newPassword !== this.confirmPassword
      ) {
        return 'Passwords do not match.';
      } else {
        return true;
      }
    },
    passwordTest() {
      // TODO: Get this value from the backend
      const strongRegex = /(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}/;
      return strongRegex.test(this.newPassword);
    },
    validation() {
      if (this.newPassword) {
        if (this.newPassword.length < 8) {
          return 'Password too short.';
        } else if (!this.passwordTest()) {
          return 'Password does not contain the required characters.';
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
