<template>
  <v-container class="debug">
    <div>
      <v-breadcrumbs
        v-if="!mainPage"
        :items="navItems"
        class="debug-breadcrumbs w-100 mt-2">
        <template #divider>
          <v-icon
            class="breadcrumb-chevron"
            icon="mdi-chevron-right"/>
        </template>
        <template #item="props">
          <v-breadcrumbs-item
            :key="props.item.name"
            :href="props.item.link"
            :target="'_self'"
            :disabled="props.item.isCurrent">
            {{ props.item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="clearfix"/>
    <router-view/>
  </v-container>
</template>

<script>
export default {
  name: 'DebugRouter',
  computed: {
    mainPage() {
      return this.$route.name === 'DebugPortal';
    },
    navItems() {
      const navItems = [
        {
          name: 'Debug Portal',
          link: '#/debug',
        },
      ];
      switch (this.$route.name) {
        case 'SentryTesting':
          navItems.push({
            name: 'Sentry Testing',
            link: '#/debug/sentry-testing',
            isCurrent: true,
          });
          break;
        case 'UserTesting':
          navItems.push({
            name: 'User Troubleshooting',
            link: '#/debug/user-testing',
            isCurrent: true,
          });
          break;
        default:
          navItems[0].isCurrent = true;
      }
      return navItems;
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/colors.scss';
.debug {
  .breadcrumbs, .debug-breadcrumbs {
    display: block;
    float: left;
    text-align: left;
    color: $bainbridge-blue;
    font-size: 14px;
    li {
      display: inline-block;
      &:last-child {
        a {
          color: $bainbridge-green-dark;
          opacity: 1 !important;
        }
      }
    }
  }
}
</style>
