export const IS_AFTER = 'is on or after';
export const IS_ANY = 'is any';
export const IS_BEFORE = 'is before';
export const IS_BETWEEN = 'is between';
export const IS_IN = 'is in';
export const IS_IN_THE_PAST = 'is in the past';
export const MATCHES = 'matches';
export const CONTAINS = 'contains';
export const STARTS_WITH = 'starts with';
export const ENDS_WITH = 'ends with';
export const IS_BLANK = 'is blank';
export const IS_NULL = 'is null';
export const DOESNT_CONTAIN = 'doesn\'t contain';
export const DOESNT_START_WITH = 'doesn\'t start with';
export const DOESNT_END_WITH = 'doesn\'t end with';
export const IS_NOT_BLANK = 'is not blank';
export const IS_NOT_NULL = 'is not null';
export const IS_EQUAL_TO = 'is equal to';
export const IS_GREATER_THAN = 'is greater than';
export const IS_GREATER_THAN_OR_EQUAL_TO = 'is greater than or equal to';
export const IS_LESS_THAN = 'is less than';
export const IS_LESS_THAN_OR_EQUAL_TO = 'is less than or equal to';
export const IS_NOT_EQUAL_TO = 'is not equal to';
export const IS_NOT_BETWEEN = 'is not between';

export default {
  IS_AFTER,
  IS_ANY,
  IS_BEFORE,
  IS_BETWEEN,
  IS_IN,
  IS_IN_THE_PAST,
  MATCHES,
  STARTS_WITH,
  ENDS_WITH,
  CONTAINS,
  IS_BLANK,
  IS_NULL,
  DOESNT_START_WITH,
  DOESNT_END_WITH,
  DOESNT_CONTAIN,
  IS_NOT_BLANK,
  IS_NOT_NULL,
  IS_EQUAL_TO,
  IS_GREATER_THAN,
  IS_GREATER_THAN_OR_EQUAL_TO,
  IS_LESS_THAN,
  IS_LESS_THAN_OR_EQUAL_TO,
  IS_NOT_EQUAL_TO,
  IS_NOT_BETWEEN,
};
