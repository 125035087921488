<template>
  <span
    class="file-status"
    :class="[parentType === 'tile' ? 'file-status-tile' : '', `file-status-${state.toLowerCase()}`]"/>
</template>

<script>
export default {
  name: 'StatusIndicator',
  components: {
  },
  props: {
    state: {
      type: String,
      required: true,
    },
    parentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statusColor: {
        Available: '#7FB459',
        Pending: '#FFD700',
        Failed: '#EF0107',
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.file-status {
  display: flex;
  width: 13px;
  height: 13px;
  border-radius: 100px;
}

.file-status-archived {
  background-image: radial-gradient(farthest-corner at 40% 35%,#cad1d5, #7b8b96); // Base color #7FB459
}

.file-status-available {
  background-image: radial-gradient(farthest-corner at 40% 35%,#cce1bd, #7fb459); // Base color #7FB459
}

.file-status-pending {
  background-image: radial-gradient(farthest-corner at 40% 35%,#ffef99, #ffd700); // Base color #FFD700
}

.file-status-failed {
  background-image: radial-gradient(farthest-corner at 40% 35%,#f9999c, #ef0107); // Base color #EF0107
}

.file-status-tile {
  margin-right: 15px;
}
</style>
