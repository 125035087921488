import moment from 'moment';

export function parseDate(dateString) {
  let formats = ['YYYY-MM-DD', 'MM-DD-YYYY', 'MM-YYYY', 'MM-YY', 'MM-DD-YY', 'YY-MM-DD'];
  formats = formats.concat(formats.map((format) => format.replace('-', '/')));
  const formattedDate = moment(dateString, formats).format('YYYY-MM-DD');
  return formattedDate;
}

export function getPreviousDate(valueString) {
  return moment().startOf('month').subtract(valueString[0], valueString.split(' ')[1]).format('YYYY-MM-DD');
}

export function now() {
  return moment();
}

export default {
  parseDate,
  getPreviousDate,
  now,
};
