import {
  Request, PUT_METHOD,
} from '@/lib/http';

const baseUrl = '/medos/api/resources/files';

export default {
  putUploadChunk(fileChunk) {
    const request = new Request(`${baseUrl}/v1/blobs/${fileChunk.meta.name}`, {
      body: fileChunk, method: PUT_METHOD,
    });
    return request.sendWithRetry();
  },
  putUploadFile(filePackage) {
    const request = new Request(`${baseUrl}/v1/files/${filePackage.meta.name}`, {
      body: filePackage, method: PUT_METHOD,
    });
    return request.sendWithRetry();
  },
  getFile(fileName) {
    const request = new Request(`${baseUrl}/v1/files/${fileName}`);
    return request.send();
  },
  getFiles(queryParams) {
    let url = `${baseUrl}/v1/files`;
    if (queryParams) url += `?${queryParams}`;
    const request = new Request(url);
    return request.send();
  },
};
