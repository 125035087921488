<template>
  <div :class="`date-filter ${formattedLabel}-row`">
    <div class="filter-label">
      <FilterLabel
        :label="label"
        :is-active="isActive"/>
    </div>
    <div>
      <v-select
        v-model="localValue.dateOption"
        variant="underlined"
        :items="possibleModifiers"
        :menu-props="{ 'contentClass': `${formattedLabel}-option-select` }"
        :disabled="!isActive"/>
    </div>
    <div
      v-if="showAbsoluteValueField">
      <v-text-field
        v-model="localValue.absoluteValue"
        variant="underlined"
        :disabled="!isActive"
        :rules="[value => value >= 1 || 'Must be at least 1']"
        class="number-field"
        type="number"
        min="1"
        validate-on="blur"/>
    </div>
    <div
      v-if="showAbsoluteValueField">
      <v-select
        v-model="localValue.absoluteValueModifier"
        variant="underlined"
        :items="possibleValueModifiers"
        :menu-props="{ 'contentClass': `${formattedLabel}-value-select` }"
        :disabled="!isActive"/>
    </div>
    <div
      v-if="showMonthYearFields"
      xs2
      class="mr-2">
      <v-select
        v-model="localValue.monthValue"
        variant="underlined"
        :items="possibleMonths"
        :menu-props="{ 'contentClass': `${formattedLabel}-month-select` }"
        :disabled="!isActive"/>
    </div>
    <div
      v-if="showMonthYearFields"
      class="flex">
      <div
        v-if="showMonthYearFields"
        class="inner-label">
        <h4>
          of
        </h4>
      </div>
      <v-select
        v-model="localValue.yearValue"
        variant="underlined"
        :items="possibleYears"
        :menu-props="{ 'contentClass': `${formattedLabel}-year-select` }"
        :disabled="!isActive"/>
    </div>
    <div
      v-if="showPrimaryDateField">
      <DateSelector
        :is-active="isActive"
        :label="primaryDateFieldLabel"
        :passed-date="localValue.primaryDateField"
        :date-format="dateFormat"
        :rules="primaryDateFieldRule"
        field="primaryDateField"
        @dateChanged="onDateChanged"/>
    </div>
    <div
      v-if="showSecondaryDateField">
      <DateSelector
        :is-active="isActive"
        :label="secondaryDateFieldLabel"
        :passed-date="localValue.secondaryDateField"
        :date-format="dateFormat"
        :rules="secondaryDateFieldRule"
        field="secondaryDateField"
        @dateChanged="onDateChanged"/>
    </div>
    <FilterActions
      :is-active="isActive"
      @removeFilter="removeFilter"/>
  </div>
</template>

<script>
import moment from 'moment';

import {
  IS_AFTER,
  IS_ANY,
  IS_BEFORE,
  IS_BETWEEN,
  IS_IN,
  IS_IN_THE_PAST,
  MATCHES,
} from '@/lib/constants';

import DateSelector from '@/components/utils/DateSelector.vue';
import FilterLabel from './FilterLabel.vue';
import FilterActions from './FilterActions.vue';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATE_OPTION = IS_ANY;
const DEFAULT_ABSOLUTE_VALUE = 12;
const DEFAULT_ABSOLUTE_VALUE_MODIFIER = 'months';
const DEFAULT_MONTH_VALUE = moment().get('month').toString();
const DEFAULT_YEAR_VALUE = moment().get('year').toString();
const DEFAULT_PRIMARY_DATE_FIELD = moment().format(DEFAULT_DATE_FORMAT);
const DEFAULT_SECONDARY_DATE_FIELD = moment().format(DEFAULT_DATE_FORMAT);

const POSSIBLE_YEARS = [...Array(100).keys()].map((offset) => {
  const year = (moment().get('year') - offset).toString();
  return {
    title: year,
    value: year,
  };
});

const POSSIBLE_MONTHS = [{
  title: 'all months',
  value: '-1',
}].concat([...Array(12).keys()].map((month) => {
  const monthName = moment().month(month).format('MMMM');
  return {
    title: monthName,
    value: month.toString(),
  };
}));

export default {
  name: 'DateFilter',
  components: {
    DateSelector,
    FilterLabel,
    FilterActions,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    passedValue: {
      type: Object,
      default: () => ({
        dateOption: DEFAULT_DATE_OPTION,
      }),
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dateFormat: DEFAULT_DATE_FORMAT,
      localValue: {
        dateOption: DEFAULT_DATE_OPTION,
        absoluteValue: DEFAULT_ABSOLUTE_VALUE,
        absoluteValueModifier: DEFAULT_ABSOLUTE_VALUE_MODIFIER,
        monthValue: DEFAULT_MONTH_VALUE,
        yearValue: DEFAULT_YEAR_VALUE,
        primaryDateField: DEFAULT_PRIMARY_DATE_FIELD,
        secondaryDateField: DEFAULT_SECONDARY_DATE_FIELD,
      },
      firstLoad: true,
      possibleModifiers: [
        { title: IS_IN_THE_PAST, value: IS_IN_THE_PAST },
        { title: IS_IN, value: IS_IN },
        { title: IS_BETWEEN, value: IS_BETWEEN },
        { title: IS_AFTER, value: IS_AFTER },
        { title: IS_BEFORE, value: IS_BEFORE },
        { title: MATCHES, value: MATCHES },
        { title: IS_ANY, value: IS_ANY },
      ],
      possibleValueModifiers: [
        { title: 'months', value: 'months' },
        { title: 'weeks', value: 'weeks' },
        { title: 'days', value: 'days' },
        { title: 'years', value: 'years' },
      ],
      possibleYears: POSSIBLE_YEARS,
      possibleMonths: POSSIBLE_MONTHS,
    };
  },
  computed: {
    formattedLabel() {
      return this.label.toLowerCase().replace(/ /g, '-');
    },
    showAbsoluteValueField() {
      return this.localValue.dateOption === IS_IN_THE_PAST;
    },
    showMonthYearFields() {
      return this.localValue.dateOption === IS_IN;
    },
    showPrimaryDateField() {
      return (
        this.localValue.dateOption === MATCHES ||
        this.localValue.dateOption === IS_AFTER ||
        this.localValue.dateOption === IS_BEFORE ||
        this.localValue.dateOption === IS_BETWEEN
      );
    },
    primaryDateFieldLabel() {
      return this.localValue.dateOption === IS_BETWEEN ? 'Start Date' : 'Date';
    },
    primaryDateFieldRule() {
      return [(value) => moment(value).diff(moment(this.localValue.secondaryDateField)) <= 0 || 'Start Date should be before End Date'];
    },
    showSecondaryDateField() {
      return this.localValue.dateOption === IS_BETWEEN;
    },
    secondaryDateFieldLabel() {
      return 'End Date';
    },
    secondaryDateFieldRule() {
      return [(value) => moment(value).diff(moment(this.localValue.primaryDateField)) >= 0 || 'End Date should be after Start Date'];
    },
    value() {
      const value = {
        dateOption: this.localValue.dateOption,
      };

      if (this.showAbsoluteValueField) {
        value.absoluteValue = this.localValue.absoluteValue;
        value.absoluteValueModifier = this.localValue.absoluteValueModifier;
      }
      if (this.showMonthYearFields) {
        value.monthValue = this.localValue.monthValue;
        value.yearValue = this.localValue.yearValue;
      }
      if (this.showPrimaryDateField) {
        value.primaryDateField = this.localValue.primaryDateField;
      }
      if (this.showSecondaryDateField) {
        value.secondaryDateField = this.localValue.secondaryDateField;
      }
      return value;
    },
  },
  watch: {
    value: {
      handler() {
        if (Object.keys(this.value).some((valueKey) => this.value[valueKey] !== this.passedValue[valueKey]) &&
          !this.firstLoad) {
          this.emitUpdate();
        }
      },
      immediate: true,
      deep: true,
    },
    passedValue: {
      handler() {
        this.localValue.dateOption = this.passedValue.dateOption;

        if (this.showAbsoluteValueField || (this.firstLoad && this.passedValue.absoluteValue)) {
          this.localValue.absoluteValue = this.passedValue.absoluteValue;
          this.localValue.absoluteValueModifier = this.passedValue.absoluteValueModifier;
        }
        if (this.showMonthYearFields || (this.firstLoad && this.passedValue.monthValue)) {
          this.localValue.monthValue = this.passedValue.monthValue;
          this.localValue.yearValue = this.passedValue.yearValue;
        }
        if (this.showPrimaryDateField || (this.firstLoad && this.passedValue.primaryDateField)) {
          this.localValue.primaryDateField = this.passedValue.primaryDateField;
        }
        if (this.showSecondaryDateField || (this.firstLoad && this.passedValue.secondaryDateField)) {
          this.localValue.secondaryDateField = this.passedValue.secondaryDateField;
        }
        this.firstLoad = false;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter', this.filterId);
    },
    emitUpdate() {
      this.$emit('valueChanged', {
        key: this.filterId,
        value: this.value,
      });
    },
    onDateChanged(changes) {
      this.localValue[changes.field] = changes.date;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.inner-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1rem 0 0;
}
.date-filter {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 3fr;
  gap: 1rem;
  .of-divider {
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>
