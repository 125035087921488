<template>
  <div class="library">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'LibraryRouter',
};
</script>
