<template>
  <div class="upload-filter-bar">
    <div class="upload-filter-bar-top-container">
      <v-btn
        icon
        variant="flat"
        @click="collapsed = !collapsed">
        <v-icon
          v-if="collapsed"
          icon="mdi-chevron-right"/>
        <v-icon
          v-else
          icon="mdi-chevron-down"/>
      </v-btn>
      <div
        @click="collapsed = !collapsed">
        <div>
          <div class="filter-header">
            Filters
          </div>
          <div
            v-if="collapsed"
            class="upload-filter-summary">
            <span
              v-for="filter in filterSummary"
              :key="filter.value">
              File Name <b>{{ filter.qualifier }}</b> <b>{{ filter.value }}</b>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!collapsed">
      <div class="filter-block">
        <div class="filter-label">
          File Name:
        </div>
        <div class="filter-qualifier">
          <v-select
            v-model="nameQualifier"
            :items="qualifiers"
            variant="underlined"
            model-value="Contains"/>
        </div>
        <div class="filter-input">
          <v-text-field
            v-model="nameFilter"
            placeholder="file name..."
            variant="underlined"
            type="text"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFilterBar',
  components: {
  },
  props: {
    rawList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true,
      nameQualifier: 'Contains',
      nameFilter: '',
      qualifiers: [
        'Contains',
        'Equal to',
      ],
    };
  },
  computed: {
    filterSummary() {
      const summary = [];
      let filterObj = {};
      if (this.nameFilter.length > 0) {
        filterObj = {
          qualifier: this.nameQualifier,
          value: this.nameFilter,
        };
        summary.push(filterObj);
      }
      return summary;
    },
    filterList() {
      let filteredList = this.rawList;
      if (this.nameFilter.length > 0) {
        const caseInsensitive = this.nameFilter.toUpperCase();
        filteredList = this.rawList.filter((el) => el.spec.name.toUpperCase().includes(caseInsensitive));
      }
      return filteredList;
    },
  },
  watch: {
    filterList(updatedList) {
      this.emitUpdatedList(updatedList);
    },
  },
  mounted() {
    this.$emit('filter-list', this.filterList);
  },
  methods: {
    emitUpdatedList(updatedList) {
      this.$emit('filter-list', updatedList);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.upload-filter-bar {
  display: flex;
  flex-direction: column;
  padding-bottom: 3px;
  border-bottom: 1px solid $bainbridge-gray-light;
  background-color: #ffffff;
}

.upload-filter-bar-top-container {
    display: flex;
}

.filter-header {
  font-size: 18px;
  padding: 18px 0 0 12px;
  color: $bainbridge-gray;
}

.upload-filter-summary {
  cursor: pointer;
  font-size: 14px;
  margin: 8px 0px 6px 20px;
  color: $bainbridge-gray;
}

.filter-block {
  display: flex;
  height: 50px;
  align-items: center;
  margin-left: 1rem;
  font-size: 14px;
  color: $bainbridge-gray;

  .filter-label {
    width: 125px;
  }

  .filter-qualifier {
    margin-right: 8px;
    width: 200px;
  }

  .filter-input {
    width: 400px;
  }
}
</style>
