export const EMAIL_PASSWORD_LOGIN_METHOD = 'setIdentity';
export const SSO_LOGIN_METHOD = 'ssoLogin';
export const INTERNAL_IDENTITY_METHOD = 'password';

export const EMPLOYEE_TENANT_NAME = 'bainbridge';

export const INTERNAL_IDENTITY_PROVIDER = 'internal';
export const GOOGLE_IDENTITY_PROVIDER = 'google.com';
export const SAML_IDENTITY_PROVIDER = 'saml';

export const ANALYTICS_CAPABILITY = 'infusions';
export const DATA_ADMIN_CAPABILITY = 'data_admin';
export const DEBUG_ADMIN_CAPABILITY = 'admin::debug';
export const EMPLOYEE_CAPABILITY = 'bainbridge_employee';
export const INTELLIGENCE_CAPABILITY = 'intelligence';
export const MANUAL_UPLOAD_CAPABILITY = 'manual_upload';
export const SSO_CONFIG_CAPABILITY = 'admin::sso';
export const USER_CONFIG_CAPABILITY = 'admin::users';

export const EMPLOYEE_IDENTITY_PROVIDERS = {
  [GOOGLE_IDENTITY_PROVIDER]: 'Single Sign-On',
  [INTERNAL_IDENTITY_PROVIDER]: 'Password',
};

export const IDENTITY_PROVIDERS = {
  [SAML_IDENTITY_PROVIDER]: 'Single Sign-On',
  [INTERNAL_IDENTITY_PROVIDER]: 'Password',
};

export const DEFAULT_MAPPING = {
  group: '',
  roles: [],
};

export function getLoginMethod(email, identityProvider) {
  let loginMethod = EMAIL_PASSWORD_LOGIN_METHOD;
  if (identityProvider && identityProvider !== INTERNAL_IDENTITY_PROVIDER) {
    loginMethod = SSO_LOGIN_METHOD;
  }
  return loginMethod;
}

export const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

export default {
  getLoginMethod,
  INTERNAL_IDENTITY_PROVIDER,
  GOOGLE_IDENTITY_PROVIDER,
  EMPLOYEE_IDENTITY_PROVIDERS,
  IDENTITY_PROVIDERS,
  EMPLOYEE_CAPABILITY,
  DEFAULT_MAPPING,
};
