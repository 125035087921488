<template>
  <div class="mb-4 user-filter-set-row">
    <v-list-item class="px-2">
      <div class="user-filter-top">
        <div
          v-if="!editing && filterSetNameUpToDate"
          class="w-100 mr-1 py-0">
          <v-tooltip
            location="bottom"
            class="w-100">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                variant="flat"
                block
                class="filter-set-name my-2 px-2"
                @click="applyFilters">
                <span>{{ localUserFilterSetName }}</span>
              </v-btn>
            </template>
            <span>Apply {{ localUserFilterSetName }}</span>
          </v-tooltip>
        </div>
        <div
          v-else
          class="w-100 mr-1 py-0">
          <v-text-field
            ref="name"
            v-model="updatedUserFilterSetName"
            :rules="[(value) => !!value]"
            :validate-on="true ? 'blur' : undefined"
            :disabled="!filterSetNameUpToDate"
            class="updated-filter-set-name w-100 mt-2"
            placeholder="Filter set name"
            @keyup.enter="updateUserFilterSetName"/>
        </div>
        <v-list-item-action class="ma-0 float-right filter-set-action">
          <ShareButton
            :content-id="userFilterSet.filterSetId"
            path-type="base"
            content-type="filter_set"
            query-param="filter_set"/>
        </v-list-item-action>
        <v-list-item-action
          v-for="action in actions"
          :key="action.icon"
          class="ma-0 float-right filter-set-action">
          <v-tooltip
            location="bottom">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :loading="action.loading"
                :disabled="action.loading || action.disabled"
                :class="action.class"
                class="ma-0"
                variant="text"
                icon
                @click="action.click">
                <v-icon
                  :icon="action.icon"/>
              </v-btn>
            </template>
            <span>{{ action.tooltip }}</span>
          </v-tooltip>
        </v-list-item-action>
      </div>
    </v-list-item>
    <v-list-item
      class="mt-1 filter-description-tile align-start">
      <div
        class="filter-description">
        <span
          v-for="filterId in filterIds"
          :key="`${userFilterSet.name}.${filterId}`"
          class="pr-1 description-content"
          v-html="userFilterSet.filters[filterId].filterSummary"/>
      </div>
    </v-list-item>
    <v-dialog
      v-model="confirmDialog"
      max-width="350">
      <v-card>
        <v-card-title
          class="text-h6 dialog-title">
          {{ confirmMessage }}
        </v-card-title>
        <v-card-actions
          class="justify-end">
          <v-btn
            :class="`${formattedName}-deny-button`"
            default
            variant="flat"
            @click="confirmDialog = false">
            No
          </v-btn>
          <v-btn
            :class="`${formattedName}-confirm-button`"
            color="primary"
            variant="flat"
            @click="confirmAction">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

import ShareButton from '@/components/utils/ShareButton.vue';

export default {
  name: 'UserFilterSetRow',
  components: {
    ShareButton,
  },
  props: {
    menuOpen: {
      type: Boolean,
      default: () => false,
    },
    userFilterSet: {
      type: Object,
      required: true,
    },
    userFilterSetId: {
      type: String,
      required: true,
    },
    areFiltersDefault: {
      type: Boolean,
      required: true,
    },
    actionComplete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localUserFilterSetName: '',
      updatedUserFilterSetName: '',
      editing: false,
      confirmDialog: false,
      confirmMessage: '',
      confirmAction: this.removeUserFilterSet,
      defaultUpToDate: true,
      filterSetNameUpToDate: true,
      filterSetUpToDate: true,
      filterSetRemoved: false,
    };
  },
  computed: {
    formattedName() {
      return this.userFilterSet.name.toLowerCase().replace(/ /g, '-');
    },
    filterIds() {
      return this.userFilterSet.filters.filterIds;
    },
    actions() {
      const actions = [
        {
          active: !this.editing,
          loading: !this.filterSetNameUpToDate,
          click: this.startEditing,
          class: 'filter-set-edit-button',
          icon: 'edit',
          tooltip: 'Edit filter set name.',
        },
        {
          active: this.editing,
          loading: !this.filterSetNameUpToDate,
          disabled: !this.updatedUserFilterSetName,
          click: this.updateUserFilterSetName,
          class: 'filter-set-save-button',
          icon: 'save',
          tooltip: 'Save filter set name.',
        },
        {
          active: !this.areFiltersDefault,
          loading: !this.filterSetUpToDate,
          click: () => this.showDialog(`Replace saved filters in ${this.userFilterSet.name} with current filters?`, this.replaceUserFilterSet),
          class: 'filter-set-replace-button',
          icon: 'autorenew',
          tooltip: 'Replace with current filters.',
        },
        {
          active: true,
          loading: !this.defaultUpToDate,
          click: this.updateDefaultUserFilterSet,
          class: this.userFilterSet.isDefault ? 'filter-set-undefault-button' : 'filter-set-default-button',
          icon: this.userFilterSet.isDefault ? 'star' : 'star_outline',
          tooltip: this.userFilterSet.isDefault ? 'Unset as default filters when you log in.' : 'Set as default filters when you log in.',
        },
        {
          active: true,
          loading: this.filterSetRemoved,
          click: () => this.showDialog(`Delete ${this.userFilterSet.name}?`, this.removeUserFilterSet),
          class: 'filter-set-remove-button',
          icon: 'clear',
          tooltip: 'Remove saved filters.',
        },
      ];
      return actions.filter((action) => action.active);
    },
  },
  watch: {
    actionComplete: {
      handler() {
        if (this.actionComplete) {
          this.filterSetUpToDate = true;
          this.defaultUpToDate = true;
          this.filterSetRemoved = false;
        }
      },
    },
    userFilterSet: {
      handler() {
        this.localUserFilterSetName = cloneDeep(this.userFilterSet.name);
        this.updatedUserFilterSetName = cloneDeep(this.userFilterSet.name);
        this.filterSetNameUpToDate = true;
        this.defaultUpToDate = true;
        this.filterSetUpToDate = true;
        this.filterSetRemoved = false;
      },
      deep: true,
      immediate: true,
    },
    menuOpen: {
      handler() {
        if (!this.menuOpen && this.editing) {
          this.localUserFilterSetName = cloneDeep(this.userFilterSet.name);
          this.updatedUserFilterSetName = cloneDeep(this.userFilterSet.name);
          this.editing = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    startEditing() {
      this.editing = true;
    },
    applyFilters() {
      this.$emit('applyFilters', this.userFilterSetId);
    },
    showDialog(message, action) {
      this.confirmDialog = true;
      this.confirmMessage = message;
      this.confirmAction = action;
    },
    replaceUserFilterSet() {
      this.filterSetUpToDate = false;
      this.$emit('replaceUserFilterSet', this.userFilterSetId);
      this.confirmDialog = false;
      this.editing = false;
    },
    removeUserFilterSet() {
      this.filterSetRemoved = true;
      this.$emit('removeUserFilterSet', this.userFilterSetId);
      this.confirmDialog = false;
    },
    updateUserFilterSetName() {
      if (!this.updatedUserFilterSetName) {
        this.$refs.name.validate(true);
      } else if (this.updatedUserFilterSetName === this.userFilterSet.name) {
        this.editing = false;
      } else {
        this.filterSetNameUpToDate = false;
        this.$emit('updateUserFilterSetName', {
          userFilterSetId: this.userFilterSetId,
          userFilterSetName: cloneDeep(this.updatedUserFilterSetName),
        });
        this.localUserFilterSetName = cloneDeep(this.updatedUserFilterSetName);
        this.editing = false;
      }
    },
    updateDefaultUserFilterSet() {
      this.defaultUpToDate = false;
      this.$emit('updateDefaultUserFilterSet', this.userFilterSetId);
      this.editing = false;
    },
  },
};
</script>

<style lang="scss">
.user-filter-set-row {
  .user-filter-top {
    display: flex;
    align-items: center;
    height: 42px;
  }
  .filter-set-name {
    padding: 0 8px;
  }
  .filter-set-name .v-btn__content {
    justify-content: flex-start;
    font-size: 14px;
  }
  .filter-set-name span {
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .updated-filter-set-name {
    padding: 0 8px;
    margin-bottom: 7px !important;
    align-items: center;
    font-size: 14px;
    .v-input__slot {
      margin-bottom: 1px;
    }
    input {
      margin: 8px 0px;
      padding: 0px 8px;
    }
  }
  .filter-set-action {
    min-width: 30px;
  }
  .filter-set-action button {
    height: 30px;
    width: 30px;
    margin: 1px;
  }
  .filter-set-action .material-icons {
    font-size: 18px;
  }
  .filter-description-tile {
    min-height: 20px;
    padding-top: 0;
    margin-top: 0;
  }
  .filter-description {
    height: 100%;
    padding: 0 8px;
    font-size: 11px;
    overflow: auto;
  }
  .dialog-title {
    line-height: unset !important;
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
