import { generateQueryParamsString, Request } from '@/lib/http';
import store from '@/store';

const baseURL = '/api/dashboards';

export default {
  getDashboards(url) {
    const tenantId = store.getters.tenant.id;
    const request = new Request(`${baseURL}/${tenantId}/collection/${url}`);
    return request.send();
  },
  getDashboard(dashboardId, query) {
    const queryString = generateQueryParamsString(query);
    const request = new Request(`${baseURL}/${dashboardId}${queryString}`);
    return request.send();
  },
};
