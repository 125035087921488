import IdentityService from '@/services/identity';
import UsersService from '@/services/users';

import { DEFAULT_MAPPING } from '@/lib/identity-methods';

export default {
  state: {
    allowedGroups: [],
    allowedRoles: [],
    allowedRolesLoaded: false,
    allowedTenants: [],
    allowedTenantsLoaded: false,
    disallowedGroups: [],
    identityConfig: null,
    identityConfigLoaded: false,
    mappedRoles: [DEFAULT_MAPPING],
    mappedRolesLoaded: false,
    securityGroupsLoaded: false,
    tenantUsers: [],
    tenantUsersLoaded: false,
    users: [],
    usersLoaded: false,
  },
  mutations: {
    RESET_USERS(state) {
      state.allowedGroups = [];
      state.allowedTenants = [];
      state.allowedTenantsLoaded = false;
      state.allowedRoles = [];
      state.allowedRolesLoaded = false;
      state.disallowedGroups = [];
      state.identityConfig = null;
      state.identityConfigLoaded = false;
      state.mappedRoles = [];
      state.mappedRolesLoaded = false;
      state.securityGroupsLoaded = false;
      state.tenantUsers = [];
      state.tenantUsersLoaded = false;
      state.users = [];
      state.usersLoaded = false;
    },
    SET_ALLOWED_ROLES(state, allowedRoles) {
      state.allowedRoles = allowedRoles;
    },
    SET_ALLOWED_ROLES_LOADED(state, allowedRolesLoaded) {
      state.allowedRolesLoaded = allowedRolesLoaded;
    },
    SET_ALLOWED_TENANTS(state, allowedTenants) {
      state.allowedTenants = allowedTenants;
    },
    SET_ALLOWED_TENANTS_LOADED(state, allowedTenantsLoaded) {
      state.allowedTenantsLoaded = allowedTenantsLoaded;
    },
    SET_IDENTITY_CONFIG(state, identityConfig) {
      state.identityConfig = identityConfig;
    },
    SET_IDENTITY_CONFIG_LOADED(state, identityConfigLoaded) {
      state.identityConfigLoaded = identityConfigLoaded;
    },
    SET_MAPPED_ROLES(state, mappedRoles) {
      state.mappedRoles = mappedRoles;
    },
    SET_MAPPED_ROLES_LOADED(state, mappedRolesLoaded) {
      state.mappedRolesLoaded = mappedRolesLoaded;
    },
    SET_NEW_TENANT_USER(state, newUser) {
      state.tenantUsers.push(newUser);
    },
    SET_SECURITY_GROUPS(state, securityGroups) {
      state.allowedGroups = securityGroups.allowed;
      state.disallowedGroups = securityGroups.disallowed;
    },
    SET_SECURITY_GROUPS_LOADED(state, securityGroupsLoaded) {
      state.securityGroupsLoaded = securityGroupsLoaded;
    },
    SET_TENANT_USERS(state, tenantUsers) {
      state.tenantUsers = tenantUsers;
    },
    SET_TENANT_USERS_LOADED(state, tenantUsersLoaded) {
      state.tenantUsersLoaded = tenantUsersLoaded;
    },
    SET_UPDATED_TENANT_USER(state, updatedUser) {
      if (updatedUser.is_active) {
        state.tenantUsers = [...state.tenantUsers.filter((user) => user.id !== updatedUser.id), updatedUser];
      } else {
        const index = state.tenantUsers.findIndex((user) => user.id === updatedUser.id);
        state.tenantUsers.splice(index, 1);
      }
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_USERS_LOADED(state, usersLoaded) {
      state.usersLoaded = usersLoaded;
    },
  },
  actions: {
    resetUsers({ commit }) {
      commit('RESET_USERS');
    },
    setAllowedRoles({ commit, rootGetters }, tenant) {
      commit('SET_ALLOWED_ROLES_LOADED', false);
      commit('SET_ALLOWED_ROLES', []);
      const tenantId = tenant ? tenant.id : rootGetters.tenant.id;
      return new Promise((resolve, reject) => {
        UsersService.getTenantAllowedRoles(tenantId).then((allowedRoles) => {
          commit('SET_ALLOWED_ROLES', allowedRoles);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('SET_ALLOWED_ROLES_LOADED', true);
        });
      });
    },
    setAllowedTenants({ commit, rootGetters }, tenant) {
      commit('SET_ALLOWED_TENANTS_LOADED', false);
      commit('SET_ALLOWED_TENANTS', []);
      const loadAllowedTenants = tenant ? tenant.allow_multiple : rootGetters.tenant.allow_multiple;
      return new Promise((resolve, reject) => {
        if (!loadAllowedTenants) {
          commit('SET_ALLOWED_TENANTS', []);
          commit('SET_ALLOWED_TENANTS_LOADED', true);
          resolve();
        } else {
          UsersService.getTenants().then((allowedTenants) => {
            commit('SET_ALLOWED_TENANTS', allowedTenants);
            resolve();
          }).catch(() => {
            reject();
          }).finally(() => {
            commit('SET_ALLOWED_TENANTS_LOADED', true);
          });
        }
      });
    },
    setIdentityConfig({ commit, rootGetters }, tenant) {
      commit('SET_IDENTITY_CONFIG_LOADED', false);
      const tenantId = tenant ? tenant.id : rootGetters.tenant.id;
      commit('SET_IDENTITY_CONFIG', null);
      return new Promise((resolve, reject) => {
        IdentityService.getTenantProvider(tenantId).then((identityConfig) => {
          if (identityConfig) {
            const config = {
              entityId: identityConfig.idpEntityId,
              ssoUrl: identityConfig.ssoUrl,
              samlCert: identityConfig.idpCertificates[0].x509Certificate,
            };
            commit('SET_IDENTITY_CONFIG', config);
          } else {
            commit('SET_IDENTITY_CONFIG', null);
          }
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('SET_IDENTITY_CONFIG_LOADED', true);
        });
      });
    },
    setMappedRoles({ commit, rootGetters }, tenant) {
      commit('SET_MAPPED_ROLES_LOADED', false);
      commit('SET_MAPPED_ROLES', [DEFAULT_MAPPING]);
      const tenantId = tenant ? tenant.id : rootGetters.tenant.id;
      return new Promise((resolve, reject) => {
        UsersService.getTenantMappedRoles(tenantId).then((mappings) => {
          const mappedRoles = [DEFAULT_MAPPING];
          mappings.forEach((mapping) => {
            mapping.roles = mapping.roles.map((r) => r.display_name);
            mappedRoles.unshift(mapping);
          });
          commit('SET_MAPPED_ROLES', mappedRoles);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('SET_MAPPED_ROLES_LOADED', true);
        });
      });
    },
    setNewUser({ commit }, newUserInfo) {
      return new Promise((resolve, reject) => {
        UsersService.postUser(newUserInfo).then((newUser) => {
          commit('SET_NEW_TENANT_USER', newUser);
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    setSecurityGroups({ commit, rootGetters }, tenant) {
      commit('SET_SECURITY_GROUPS_LOADED', false);
      const securityGroups = {
        allowed: [],
        disallowed: [],
      };
      commit('SET_SECURITY_GROUPS', securityGroups);
      const tenantId = tenant ? tenant.id : rootGetters.tenant.id;
      return new Promise((resolve, reject) => {
        UsersService.getTenantSecurityGroups(tenantId).then((groups) => {
          if (groups.allowed_groups.length > 0 || groups.disallowed_groups.length > 0) {
            securityGroups.allowed = groups.allowed_groups;
            securityGroups.disallowed = groups.disallowed_groups;
          }
          commit('SET_SECURITY_GROUPS', securityGroups);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('SET_SECURITY_GROUPS_LOADED', true);
        });
      });
    },
    setUpdatedUser({ commit }, updatedUserInfo) {
      return new Promise((resolve, reject) => {
        UsersService.putUser(updatedUserInfo).then((updatedUser) => {
          commit('SET_UPDATED_TENANT_USER', updatedUser);
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    setUsers({ commit }) {
      commit('SET_USERS_LOADED', false);
      commit('SET_USERS', []);
      return new Promise((resolve, reject) => {
        UsersService.getUsers().then((users) => {
          commit('SET_USERS', users);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('SET_USERS_LOADED', true);
        });
      });
    },
    setTenantUsers({ commit, rootGetters }, tenant) {
      commit('SET_TENANT_USERS_LOADED', false);
      commit('SET_TENANT_USERS', []);
      const tenantId = tenant ? tenant.id : rootGetters.tenant.id;
      return new Promise((resolve, reject) => {
        UsersService.getTenantUsers(tenantId).then((users) => {
          commit('SET_TENANT_USERS', users);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('SET_TENANT_USERS_LOADED', true);
        });
      });
    },
  },
  getters: {
    allowedGroups: (state) => state.allowedGroups,
    allowedRoles: (state) => state.allowedRoles,
    allowedRolesLoaded: (state) => state.allowedRolesLoaded,
    allowedTenants: (state) => state.allowedTenants,
    allowedTenantsLoaded: (state) => state.allowedTenantsLoaded,
    disallowedGroups: (state) => state.disallowedGroups,
    mappedRoles: (state) => state.mappedRoles,
    mappedRolesLoaded: (state) => state.mappedRolesLoaded,
    identityConfig: (state) => state.identityConfig,
    identityConfigLoaded: (state) => state.identityConfigLoaded,
    securityGroupsLoaded: (state) => state.securityGroupsLoaded,
    tenantUsers: (state) => state.tenantUsers,
    tenantUsersLoaded: (state) => state.tenantUsersLoaded,
    users: (state) => state.users,
    usersLoaded: (state) => state.usersLoaded,
  },
};
