<template>
  <div class="sign-in-container">
    <div
      class="re-fill-layout"
      column
      justify-center>
      <div
        class="disable-flex-grow"
        justify-center>
        <img
          class="bainbridge-name-horiz mx-4"
          src="~@/assets/images/BH-Horizontal.svg">
      </div>
      <div
        class="disable-flex-grow"
        justify-center>
        <v-card class="mt-6 input-container">
          <v-card-title class="text-white bg-primary">
            {{ state }}
          </v-card-title>
          <v-card-text class="pa-6">
            <EnterEmail
              :passed-identity="email"
              :security-scan="true"
              @setEmail="setEmail"/>
            <EnterPassword
              :security-scan="true"
              :email="email"
              @switchPage="switchPage"/>
            <ForgotPassword
              v-if="page === 'forgot'"
              :passed-email="email"
              :security-scan="true"
              @setEmail="setEmail"
              @switchPage="switchPage"/>
            <div class="clearfix"/>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EnterEmail from './signin/EnterEmail.vue';
import EnterPassword from './signin/EnterPassword.vue';
import ForgotPassword from './signin/ForgotPassword.vue';

export default {
  name: 'LoginPortalSecurity',
  components: {
    EnterEmail,
    EnterPassword,
    ForgotPassword,
  },
  data() {
    return {
      page: '',
      email: '',
      state: 'Login',
    };
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
  },
  created() {
    if (this.user) {
      this.$router.push({ name: 'Summary' });
    }
  },
  methods: {
    switchPage(page) {
      this.page = page;
      if (page === 'forgot') {
        this.state = 'Request Password Reset';
      } else {
        this.state = 'Login';
      }
    },
    setEmail(email) {
      this.email = email;
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-in-container {
  .re-fill-layout {
    height: 80vh;
  }
  .disable-flex-grow {
    flex-grow: 0;
  }
  .bainbridge-name-horiz {
    max-width: 800px;
  }
  .input-container {
    width: 580px;
  }
}
</style>
