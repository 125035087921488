// event-log.js
// Event Log class that captures information about a user's interaction with the portal.
import store from '@/store';

export class EventLog {
  constructor(obj) {
    this.default_tenant = obj.default_tenant;
    this.event = obj.event;
    this.error = obj.error;
    this.retries = obj.retries;
    this.capability = obj.capability;
    this.identity_tenant = obj.identity_tenant;
    this.identity_provider = obj.identity_provider;
    this.module = obj.module || store.getters.module;
    this.sub_module = obj.subModule || store.getters.subModule;
    this.dashboard = obj.dashboard || store.getters.dashboard;
    this.vendor = obj.vendor || store.getters.vendor;
    this.filter_set_id = obj.filterSetId;
    this.link = obj.link;
    this.source = obj.source;
    this.old_tenant = obj.oldTenant;
    this.new_tenant = obj.newTenant;
    this.new_user = obj.newUser;
    this.update_user = obj.update_user;
    this.report_file_path = obj.reportFilePath;
    this.report_name = obj.reportName;
    this.looker_data = obj.lookerData;
    this.user_filter_set_id = obj.userFilterSetId;
    this.user_filter_set_name = obj.userFilterSetName;
    this.message = obj.message;
    this.level = obj.level;
    this.tracked_user = obj.trackedUser;
    this.arguments = obj.arguments;
    this.target = obj.target;
    this.key_pressed = obj.keyPressed;
    this.milliseconds = obj.milliseconds;
    this.start_menu = obj.startMenu;
    this.end_menu = obj.endMenu;
    this.start_tab = obj.startTab;
    this.end_tab = obj.endTab;
    this.user_info = this.setUserInfo(obj);
  }

  setUserInfo(obj) {
    const userInfo = {
      capabilities: [],
      email: '',
      id: '',
      tenant: '',
      track_id: sessionStorage.getItem('track_id'),
      resolution: {
        width: window.screen.width,
        height: window.screen.height,
      },
      portal_version: VERSION,
    };

    Object.keys(userInfo).forEach((key) => {
      if (Object.keys(obj).find((k) => k === key)) {
        userInfo[key] = obj[key];
      } else if (store.getters.user && store.getters.user[key]) {
        userInfo[key] = store.getters.user[key];
      } else if (store.getters[key] && store.getters[key].name) {
        userInfo[key] = store.getters[key].name;
      } else if (key === 'capabilities') {
        if (store.getters.capabilities && store.getters.tenant) {
          userInfo.capabilities = store.getters.capabilities;
        }
      }
    });

    return userInfo;
  }
}

export default {
  EventLog,
};
