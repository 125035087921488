import colors from '../sass/colors.scss';

export default {
  theme: {
    themes: {
      light: {
        colors: {
          primary: colors.bainbridge_blue,
          secondary: colors.bainbridge_green,
          accent: colors.bainbridge_blue_light_1,
          error: colors.bainbridge_red,
          info: colors.bainbridge_blue_light_1,
          success: colors.bainbridge_green,
          warning: colors.bainbridge_gold,
        },
      },
    },
  },
};
