import { Request } from '@/lib/http';

const baseURL = '/api/intelligence';

export default {
  getIntelligenceReports(tenantId) {
    const request = new Request(`${baseURL}/${tenantId}/intelligence-reports`);
    return request.send();
  },
};
