<template>
  <v-container
    class="intelligence-reports"
    fluid>
    <v-slide-y-transition mode="out-in">
      <v-col>
        <v-row
          xs12
          sm8
          offset-sm2
          justify="center">
          <span
            v-if="reportsState === 'loading'">
            <v-card>
              <v-card-title>
                <v-progress-circular
                  class="center"
                  color="primary"
                  indeterminate/>
              </v-card-title>
            </v-card>
          </span>
          <span
            v-else-if="reportsState === 'error'">
            <v-card>
              <v-card-title>
                <div>
                  <h3 class="text-h5">
                    There was an error loading your Intelligence Reports.
                  </h3>
                </div>
              </v-card-title>
            </v-card>
          </span>
          <span
            v-else>
            <span
              v-if="reports.length === 0">
              <v-card>
                <v-card-title>
                  <div>
                    <h3 class="text-h5">
                      There are no Intelligence Reports available.
                    </h3>
                  </div>
                </v-card-title>
              </v-card>
            </span>
            <span v-else>
              <v-row>
                <v-spacer/>
                <v-btn
                  id="report-sort-toggle"
                  color="primary"
                  variant="text"
                  @click="toggleSort()">
                  <span v-if="descending">
                    Newest <v-icon icon="mdi-chevron-down"/>
                  </span>
                  <span v-else>
                    Oldest <v-icon icon="mdi-chevron-up"/>
                  </span>
                </v-btn>
              </v-row>
              <ReportCard
                v-for="report in items"
                :key="report.title"
                :report="report"/>
            </span>
          </span>
        </v-row>
      </v-col>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import ReportCard from './ReportCard.vue';

export default {
  name: 'IntelligenceReports',
  components: {
    ReportCard,
  },
  data() {
    return {
      descending: true,
    };
  },
  computed: {
    items() {
      const items = cloneDeep(this.reports);
      items.sort((a, b) => (this.descending ? (
        moment(b.date_created).valueOf() - moment(a.date_created).valueOf()
      ) : moment(a.date_created).valueOf() - moment(b.date_created).valueOf()));
      return items;
    },
    ...mapGetters([
      'reports',
      'reportsState',
    ]),
  },
  watch: {
    '$route.name': {
      handler(newValue, prevValue) {
        if (newValue === 'Intelligence' && prevValue !== 'Intelligence' && this.reportsState !== 'loading') {
          const now = moment();
          const menuData = {
            timestamp: now,
            menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if ((this.$route && this.$route.name === 'Intelligence') && this.reportsState !== 'loading') {
      const now = moment();
      const menuData = {
        timestamp: now,
        menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
      };
      this.setMenuNavigationEnd(menuData);
    }
  },
  methods: {
    toggleSort() {
      this.descending = !this.descending;
    },
    ...mapActions([
      'setMenuNavigationEnd',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.intelligence-reports {
  margin-top: 3rem;
  .center {
    margin-left: auto;
    margin-right: auto;
  }
  .headline {
    word-break: break-word;
  }
}
</style>
