import { Request } from '@/lib/http';

const baseURL = '/api/library';

export default {
  getLibrary() {
    const request = new Request(`${baseURL}/`);
    return request.send();
  },
};
