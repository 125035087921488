<template>
  <div class="w-100 outer-container">
    <v-progress-circular
      v-if="!filtersLoaded"
      indeterminate
      class="text-primary loader"/>
    <AnalyticsDashboard
      v-else
      :are-filters-saved="areFiltersSaved"
      :action-complete="actionComplete"
      @saveFilters="saveFilters"
      @updateDefaultUserFilterSet="updateDefaultUserFilterSet"
      @replaceUserFilterSet="replaceUserFilterSet"
      @removeUserFilterSet="removeUserFilterSet"/>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';
import { EventLog } from '@/lib/event-log';
import { filterHasValue, parseLookerQueryString } from '@/lib/filter-methods';
import { objectsEqual, removePrivateKeysFromObj } from '@/lib/compare';

import AnalyticsDashboard from './AnalyticsDashboard/AnalyticsDashboard.vue';

export default {
  name: 'AnalyticsRouter',
  components: {
    AnalyticsDashboard,
  },
  data() {
    return {
      actionComplete: true,
      areFiltersSaved: true,
    };
  },
  computed: {
    areFilterQueryParamsSet() {
      const queryParams = { ...this.$route.query };
      const paramKeys = Object.keys(queryParams);
      return paramKeys.length > 0 && (paramKeys.includes('filter_set') ||
        paramKeys.some((key) => this.filters.filterIds.some((id) => this.filters[id].fieldName === key)));
    },
    ...mapGetters([
      'collectionId',
      'cumulativeFilters',
      'defaultUserFilterSet',
      'filters',
      'filterSet',
      'filterSetId',
      'filtersLoaded',
      'menus',
      'user',
      'userFilterSets',
    ]),
  },
  watch: {
    '$route.meta': {
      handler(newValue, prevValue) {
        if (newValue && prevValue) {
          const query = { ...this.$route.query };
          if (newValue.analyticsMenu && !prevValue.analyticsMenu) {
            if (this.filterSetId) {
              query.filter_set = this.filterSetId;
            } else if (!query.filter_set) {
              delete query.filter_set;
            }
            if (!objectsEqual(this.$route.query, query)) {
              this.$router.replace({ query });
            }
          } else if (!newValue.analyticsMenu && prevValue.analyticsMenu) {
            delete query.tab;
            delete query.filter_set;
            if (!objectsEqual(this.$route.query, query)) {
              this.$router.replace({ query });
            }
          }
        }
      },
      deep: true,
    },
    '$route.params': {
      handler() {
        if (this.$route.params.id && this.$route.params.id !== this.collectionId) {
          this.setCollectionId(this.$route.params.id);
        }
      },
      deep: true,
    },
    '$route.query': {
      handler(newValue, prevValue) {
        if (this.filtersLoaded && this.areFilterQueryParamsSet) {
          this.fetchFilterSetQuery();
        } else if (this.filtersLoaded && (!newValue || !newValue.filter_set) && this.$route.meta.analyticsMenu) {
          this.setFilterSetId();
          const refreshList = []
          this.filters.filterIds.forEach((filterId) => {
            const changed = this.filters[filterId].value;
            if (changed) {
              this.setFilterValue({ id: filterId, key: 'value', value: null });
              refreshList.push(refreshList)
            }
          });
          this.refreshFacetedFilters(refreshList);
        }
      },
      deep: true,
    },
    'filtersLoaded': {
      handler() {
        if (this.filtersLoaded && this.areFilterQueryParamsSet) {
          this.fetchFilterSetQuery();
        }
      },
    },
    'filterSetId': {
      handler(newValue, prevValue) {
        if (newValue !== prevValue) {
          const query = { ...this.$route.query };
          if (this.$route.meta.analyticsMenu) {
            if (this.filterSetId) {
              query.filter_set = this.filterSetId;
            } else {
              delete query.filter_set;
            }
            if (!objectsEqual(this.$route.query, query)) {
              this.$router.replace({ query });
            }
          } else {
            delete query.filter_set;
            if (!objectsEqual(this.$route.query, query)) {
              this.$router.replace({ query });
            }
          }
        }
      },
    },
  },
  methods: {
    emitFilterSetLog(success, logEvent, filterSetId, userFilterSetId, userFilterSetName, error, messageEvent) {
      const loggingData = new EventLog({
        event: success ? `filter_set.${logEvent}` : `filter_set.fail_${logEvent}`,
        filterSetId,
        userFilterSetId,
        userFilterSetName,
      });
      if (!success) {
        this.$notify(`There was an error ${messageEvent} your filter set.`);
        loggingData.error = error;
      }
      this.$services.users.postTrackingLog(loggingData);
    },
    fetchFilterSetQuery() {
      const queryParams = { ...this.$route.query };
      const paramKeys = Object.keys(queryParams);
      if (paramKeys.includes('filter_set')) {
        const filterSetId = this.$route.query.filter_set;
        if (filterSetId !== this.filterSetId) {
          this.setFilterSetId(filterSetId);
          this.$services.filters.getFilterSet(filterSetId).then((filterSet) => {
            if (filterSet.id !== filterSetId) {
              this.setFilterSetId(filterSet.id);
              this.$notify('You used an outdated link. Please use current URL from now on.');
            }
            let refreshList = []
            this.filters.filterIds.forEach((filterId) => {
              const setValue = filterId in filterSet.data.filters;
              const filterValue = setValue ? cloneDeep(filterSet.data.filters[filterId]) : null;
              const changed = this.filters[filterId].value !== removePrivateKeysFromObj(filterValue);
              if (changed) {
                this.setFilterValue({ id: filterId, key: 'value', value: removePrivateKeysFromObj(filterValue) });
                refreshList.push(filterId)
              }
            });
            this.refreshFacetedFilters(refreshList)
            this.fetchLookerQueryParams();
          });
        } else {
          this.fetchLookerQueryParams();
        }
      } else {
        this.fetchLookerQueryParams();
      }
    },
    fetchLookerQueryParams() {
      const queryParams = { ...this.$route.query };
      const paramKeys = Object.keys(queryParams);
      if (paramKeys.some((key) => this.filters.filterIds.some((id) => this.filters[id].fieldName === key))) {
        const nonFilterParams = {};
        paramKeys.forEach((key) => {
          if (!this.filters.filterIds.some((id) => this.filters[id].fieldName === key)) {
            nonFilterParams[key] = queryParams[key];
            delete queryParams[key];
          }
        });
        const lookerFilters = parseLookerQueryString(queryParams, this.filters, false);
        let refreshList = []
        lookerFilters.filterIds.forEach((filterId) => {
          const filter = this.filters[filterId];
          const lookerFilter = lookerFilters[filterId];
          const filterValue = filterHasValue(lookerFilter, filter) ? lookerFilter.value : null;
          const changed = this.filters[filterId].value !== filterValue;
          if (changed) {
            this.setFilterValue({ id: filterId, key: 'value', value: filterValue });
            refreshList.push(filterId)
          }
        });
        this.refreshFacetedFilters(refreshList)
        if (this.filterSet && !this.filterSet.isEmpty) {
          this.filterSet.saveFilterSet().then((response) => {
            if (!objectsEqual(this.$route.query, nonFilterParams)) {
              this.$router.replace({ query: nonFilterParams });
            }
            this.setFilterSetId(response.success);
          });
        } else {
          if (!objectsEqual(this.$route.query, nonFilterParams)) {
            this.$router.replace({ query: nonFilterParams });
          }
          this.setFilterSetId(null);
        }
      }
    },
    refreshUserFilterSets(newSetId) {
      this.setUserFilterSetsLoaded(false);
      this.setUserFilterSets(true).then(() => {
        if (newSetId) {
          const { filterSetId, name } = this.userFilterSets[newSetId];
          this.emitFilterSetLog(true, 'save', filterSetId, newSetId, name, null, null);
          this.areFiltersSaved = true;
        } else {
          this.actionComplete = true;
        }
      });
    },
    /**
     * Save passed filter set to user's saved filter sets.
     */
    saveFilters(userFilterSet) {
      this.areFiltersSaved = false;
      userFilterSet.user = this.user.id;
      const event = 'save';
      const action = 'saving';
      userFilterSet.save().then((response) => {
        if (!response.success) {
          this.emitFilterSetLog(false, event, null, null, userFilterSet.name, null, action);
          this.areFiltersSaved = true;
        } else {
          this.refreshUserFilterSets(response.success);
        }
      }).catch((error) => {
        this.emitFilterSetLog(false, event, null, null, userFilterSet.name, error.message, action);
        this.areFiltersSaved = true;
      });
    },
    /**
     * Replace user's default filter set with passed filter set id.
     */
    updateDefaultUserFilterSet(userFilterSetId) {
      this.actionComplete = false;
      const { filterSetId, name, isDefault } = this.userFilterSets[userFilterSetId];
      const event = isDefault ? 'set_default' : 'unset_default';
      const action = 'changing the default status for';
      this.userFilterSets[userFilterSetId].updateDefaultStatus(!this.defaultUserFilterSet.isEmpty).then((response) => {
        if (!response.success) {
          this.emitFilterSetLog(false, event, filterSetId, userFilterSetId, name, null, action);
          this.actionComplete = true;
        } else {
          this.emitFilterSetLog(true, event, filterSetId, userFilterSetId, name, null, null);
          this.refreshUserFilterSets();
        }
      }).catch((error) => {
        this.emitFilterSetLog(false, event, filterSetId, userFilterSetId, name, error.message, action);
        this.actionComplete = true;
      });
    },
    /**
     * Replace filter set in user's saved filter sets with passed filter set.
     */
    replaceUserFilterSet(updates) {
      this.actionComplete = false;
      const { userFilterSetId, userFilterSet } = updates;
      const { filterSetId, name } = this.userFilterSets[userFilterSetId];
      const event = 'update';
      const action = 'updating';
      this.userFilterSets[userFilterSetId].update(userFilterSet).then((response) => {
        if (!response.success) {
          this.emitFilterSetLog(false, event, filterSetId, userFilterSetId, name, null, action);
          this.actionComplete = true;
        } else {
          this.emitFilterSetLog(true, event, filterSetId, userFilterSetId, name, null, null);
          this.refreshUserFilterSets();
        }
      }).catch((error) => {
        this.emitFilterSetLog(false, event, filterSetId, userFilterSetId, name, error.message, action);
        this.actionComplete = true;
      });
    },
    /**
     * Remove passed filter set from user's saved filter sets.
     */
    removeUserFilterSet(userFilterSetId) {
      this.actionComplete = false;
      const { filterSetId, name } = this.userFilterSets[userFilterSetId];
      const event = 'delete';
      const action = 'deleting';
      this.userFilterSets[userFilterSetId].delete().then((response) => {
        if (!response.success) {
          this.emitFilterSetLog(false, event, filterSetId, userFilterSetId, name, null, action);
          this.actionComplete = true;
        } else {
          this.emitFilterSetLog(true, event, filterSetId, userFilterSetId, name, null, null);
          this.refreshUserFilterSets();
        }
      }).catch((error) => {
        this.emitFilterSetLog(false, event, filterSetId, userFilterSetId, name, error.message, action);
        this.actionComplete = true;
      });
    },
    ...mapActions([
      'refreshFacetedFilters',
      'setCollectionId',
      'setFilterValue',
      'setFilterSetId',
      'setUserFilterSets',
      'setUserFilterSetsLoaded',
    ]),
  },
};
</script>
<style lang="scss">
.outer-container {
  display: flex;
  height: calc(98%)
}
</style>
