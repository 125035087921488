export function hashAsString(arrayBuffer) {
  const uint8ViewOfHash = new Uint8Array(arrayBuffer);
  return Array.from(uint8ViewOfHash)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}

export function runFileReader(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      const hashAsArrayBuffer = await window.crypto.subtle.digest('SHA-256', e.target.result);
      const hashString = hashAsString(hashAsArrayBuffer);
      const fileChunk = { hash: hashString, data: e.target.result, size: file.size };
      resolve(fileChunk);
    };

    fileReader.onerror = () => {
      reject();
    };

    fileReader.readAsArrayBuffer(file);
  });
}

export default {
  runFileReader,
  hashAsString,
};
