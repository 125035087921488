<template>
  <div>Waiting...</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LandingPage',
  data() {
    return {
      state: 'loading',
    };
  },
  computed: {
    defaultRoute() {
      if (this.multiFactorRequired) {
        return 'profile';
      } else if (this.menus !== null && this.menus.length > 0) {
        return this.menus[0].url;
      }

      return null;
    },
    ...mapGetters([
      'menus',
      'multiFactorRequired',
    ]),
  },
  watch: {
    defaultRoute(route) {
      if (this.state !== 'loading' && route !== null && this.$route.path !== route) {
        this.$router.push({
          path: route,
        });
      }
    },
  },
  mounted() {
    this.state = 'loading';
    if (this.defaultRoute != null && this.$route.path !== this.defaultRoute) {
      this.$router.push({
        path: this.defaultRoute,
      });
      this.state = 'loaded';
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
