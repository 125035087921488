<template>
  <ContentModal
    :is-visible="showModal"
    :allow-click-away="false"
    size="medium"
    @onModalClose="closeModal()">
    <template
      #header-content>
      <div>
        Edit
      </div>
    </template>
    <template
      #content>
      <div class="upload-content">
        <div class="form-inputs">
          <v-text-field
            v-model="updateFileName"
            label="File Name"
            style="height: 50px; margin-top: 0;"
            variant="outlined"
            density="compact"/>
        </div>
        <div>
          <v-btn
            id="confirm-upload"
            color="secondary"
            @click="updateFile">
            Update
          </v-btn>
        </div>
      </div>
    </template>
  </ContentModal>
</template>

<script>
import { mapActions } from 'vuex';
import ContentModal from '@/components/utils/modal/ContentModal.vue';

export default {
  name: 'EditResource',
  components: {
    ContentModal,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    selectedFile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateFileName: '',
    };
  },
  mounted() {
    this.updateFileName = this.selectedFile.spec.name;
  },
  methods: {
    ...mapActions([
      'putUploadFile',
      'fetchFile',
    ]),
    async updateFile() {
      const updatedBody = {
        ...this.selectedFile,
        spec: {
          ...this.selectedFile.spec,
          name: this.updateFileName,
        },
      };
      this.closeModal();
      await this.putUploadFile(updatedBody);
      this.fetchFile(updatedBody.meta.name);
    },
    closeModal() {
      this.$emit('onModalClose');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/colors.scss";

#confirm-upload {
  margin: 15px !important;
  height: 40px;
  width: 120px;
  font-weight: 600;
}
.upload-content {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-inputs {
  margin: 2rem 2rem;
}
</style>
