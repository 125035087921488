import { Request } from '@/lib/http';

const baseUrl = '/api/files-api';

export default {
  getFileList(filePath) {
    const request = new Request(`${baseUrl}/list?path=${filePath}`);
    return request.send();
  },
  getFileDownload(filePath) {
    const request = new Request(`${baseUrl}/download?path=${filePath}`, { raw: true });
    return request.send();
  },
};
