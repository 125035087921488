// filter-methods.js
// Parses filter information into different formats.

import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { objectsEqual } from '@/lib/compare';
import { Filter } from '@/lib/filter';
import { FilterSet } from '@/lib/filter-set';

import {
  IS_AFTER,
  IS_ANY,
  IS_BEFORE,
  IS_BETWEEN,
  IS_IN,
  IS_IN_THE_PAST,
  MATCHES,
  STARTS_WITH,
  ENDS_WITH,
  CONTAINS,
  IS_BLANK,
  IS_NULL,
  DOESNT_START_WITH,
  DOESNT_END_WITH,
  DOESNT_CONTAIN,
  IS_NOT_BLANK,
  IS_NOT_NULL,
  IS_EQUAL_TO,
  IS_GREATER_THAN,
  IS_GREATER_THAN_OR_EQUAL_TO,
  IS_LESS_THAN,
  IS_LESS_THAN_OR_EQUAL_TO,
  IS_NOT_EQUAL_TO,
  IS_NOT_BETWEEN,
} from '@/lib/constants';

const formatMonth = (month) => {
  if (month === '-1') {
    return 'all months';
  }
  return moment().month(month).format('MMMM');
};

const calculateDateRange = (monthValue, yearValue) => {
  if (monthValue === '-1') {
    const year = moment().year(yearValue);
    return { startDate: year.startOf('year').format('YYYY-MM-DD'), endDate: year.endOf('year').format('YYYY-MM-DD') };
  }
  const month = moment().year(yearValue).month(monthValue);
  return { startDate: month.startOf('month').format('YYYY-MM-DD'), endDate: month.endOf('month').format('YYYY-MM-DD') };
};

const convertMappedValues = (mapping, option, values) => {
  let mappedValues = values;
  if (values.length === 0 && ![IS_BLANK, IS_NULL, IS_NOT_BLANK, IS_NOT_NULL].includes(option)) {
    return [''];
  }
  switch (option) {
    case IS_EQUAL_TO:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => val.toLowerCase() === value.toLowerCase())));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
      break;
    case IS_NOT_EQUAL_TO:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => val.toLowerCase() !== value.toLowerCase())));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
      break;
    case STARTS_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => val.toLowerCase().startsWith(value.toLowerCase()))));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
      break;
    case DOESNT_START_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => !val.toLowerCase().startsWith(value.toLowerCase()))));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
      break;
    case CONTAINS:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => val.toLowerCase().includes(value.toLowerCase()))));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
      break;
    case DOESNT_CONTAIN:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => !val.toLowerCase().includes(value.toLowerCase()))));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
      break;
    case ENDS_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => val.toLowerCase().endsWith(value.toLowerCase()))));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
      break;
    case DOESNT_END_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping
          .map((m) => Object.values(m)[0]).filter((val) => !val.toLowerCase().endsWith(value.toLowerCase()))));
      });
      mappedValues = mappedValues.map((val) => Object.keys(mapping
        .find((map) => Object.values(map)[0].toLowerCase() === val.toLowerCase()) || {})[0] || val);
  }
  return mappedValues;
};

const reverseConvertMappedValues = (mapping, option, values) => {
  let mappedValues = values;
  if ((!values || values.length === 0) && ![IS_BLANK, IS_NULL, IS_NOT_BLANK, IS_NOT_NULL].includes(option)) {
    return [''];
  }
  switch (option) {
    case IS_EQUAL_TO:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping.map((m) => Number(Object.keys(m)[0])).filter((key) => key === Number(value))));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
      break;
    case IS_NOT_EQUAL_TO:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping.map((m) => Number(Object.keys(m)[0])).filter((key) => key !== Number(value))));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
      break;
    case STARTS_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(
          mapping.map((m) => Number(Object.keys(m)[0])).filter((key) => key.startsWith(Number(value)))
        ));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
      break;
    case DOESNT_START_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(
          mapping.map((m) => Number(Object.keys(m)[0])).filter((key) => !key.startsWith(Number(value)))
        ));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
      break;
    case CONTAINS:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping.map((m) => Number(Object.keys(m)[0])).filter(
          (key) => key.includes(Number(value)),
        )));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
      break;
    case DOESNT_CONTAIN:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping.map((m) => Number(Object.keys(m)[0])).filter(
          (key) => !key.includes(Number(value)),
        )));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
      break;
    case ENDS_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping.map((m) => Number(Object.keys(m)[0])).filter(
          (key) => key.endsWith(Number(value)),
        )));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
      break;
    case DOESNT_END_WITH:
      mappedValues = [];
      values.forEach((value) => {
        mappedValues.push(...(mapping.map((m) => Number(Object.keys(m)[0])).filter(
          (key) => !key.endsWith(Number(value)),
        )));
      });
      mappedValues = mappedValues.map(
        (val) => Object.values(mapping.find((m) => Number(Object.keys(m)[0]) === Number(val)))[0] || val,
      );
  }
  return mappedValues;
};

// Get filter summary statement based on filter type and value
export function getFilterSummary(filter) {
  let filterValue;
  if (filter.value) {
    filterValue = cloneDeep(filter.value);
  } else if (filter.dashboardDefaultValue) {
    filterValue = cloneDeep(filter.dashboardDefaultValue);
  } else {
    filterValue = cloneDeep(filter.defaultValue);
  }

  let filterDisplayName;
  if (filter.dashboardDisplayName) {
    filterDisplayName = filter.dashboardDisplayName;
  } else {
    filterDisplayName = filter.displayName;
  }

  // Format the filter display name to set the ID for the element
  const filterDisplayNameId = filterDisplayName.toLowerCase().replace(/ /g, '-');

  // Using an inline-style as these elements are created "outside" of the component's CSS context
  const elementHeader = `${filterDisplayName} <span class="${filterDisplayNameId}-filter-summary" style="font-weight: 600;">`;
  const elementFooter = '</span>';

  if (!filterValue || (!filterValue.value && filter.type !== 'date' && filter.type !== 'text')) {
    return `${elementHeader} is any ${elementFooter}`;
  }
  if (filter.type === 'text') {
    const { option } = filterValue;

    if (option === IS_BLANK) {
      return `${elementHeader}
        is blank
      ${elementFooter}`;
    }
    if (option === IS_NULL) {
      return `${elementHeader}
        is null
      ${elementFooter}`;
    }
    if (option === IS_NOT_BLANK) {
      return `${elementHeader}
        is not blank
      ${elementFooter}`;
    }
    if (option === IS_NOT_NULL) {
      return `${elementHeader}
        is not null
      ${elementFooter}`;
    }
    if (!filterValue.value) {
      return `${elementHeader}
        is any
      ${elementFooter}`;
    }
  }
  if (filter.type === 'date') {
    const { dateOption } = filterValue;

    if (dateOption === IS_IN_THE_PAST) {
      return `${elementHeader}
        is in the past ${filterValue.absoluteValue} ${filterValue.absoluteValueModifier}
      ${elementFooter}`;
    }
    if (dateOption === IS_ANY) {
      return `${elementHeader}
        is any
      ${elementFooter}`;
    }
    if (dateOption === IS_IN) {
      return `${elementHeader}
        is in ${formatMonth(filterValue.monthValue)} of ${filterValue.yearValue}
      ${elementFooter}`;
    }
    if (dateOption === MATCHES) {
      return `${elementHeader}
        is ${filterValue.primaryDateField}
      ${elementFooter}`;
    }
    if (dateOption === IS_BEFORE) {
      return `${elementHeader}
        is before ${filterValue.primaryDateField}
      ${elementFooter}`;
    }
    if (dateOption === IS_AFTER) {
      return `${elementHeader}
        is on or after ${filterValue.primaryDateField}
      ${elementFooter}`;
    }
    if (dateOption === IS_BETWEEN) {
      return `${elementHeader}
        is between ${filterValue.primaryDateField} and ${filterValue.secondaryDateField}
      ${elementFooter}`;
    }
    return 'All time';
  } else if (
    filter.type === 'text' ||
    filter.type === 'number'
  ) {
    if (filterValue.option === IS_BETWEEN || filterValue.option === IS_NOT_BETWEEN) {
      return `${elementHeader}
      ${filterValue.option} ${filterValue.value} and ${filterValue.secondaryValue} (${filterValue.secondaryOption})
      ${elementFooter}`;
    } else {
      if (filter.multiple === false) {
        return `${elementHeader}
        ${filterValue.option} ${Array.isArray(filterValue.value) ? filterValue.value[0] : filterValue.value.split(',')[0]}
        ${elementFooter}`;
      }
      return `${elementHeader}
      ${filterValue.option} ${Array.isArray(filterValue.value) ? filterValue.value.join(', ') : filterValue.value.split(',').join(', ')}
      ${elementFooter}`;
    }
  } else {
    return `${elementHeader}
      is ${filterValue.value}
      ${elementFooter}`;
  }
}

// Get autocomplete query value for a passed Filter object
export function getAutocompleteQueryValue(filter) {
  const filterValueObj = filter.value || {};
  const { mappedValues } = filter || null;
  let value = '';

  if (filter.type === 'text') {
    let filterOption = filterValueObj.option || '';
    let filterValue = filterValueObj.value || [];
    if (!Array.isArray(filterValue)) {
      filterValue = filterValue.split(/,/g);
    }
    let values = filterValue;
    if (mappedValues) {
      if (![IS_BLANK, IS_NULL, IS_NOT_BLANK, IS_NOT_NULL].includes(filterOption)) {
        values = convertMappedValues(mappedValues, filterOption, values);
        if (values.length === 0) {
          filterOption = IS_BLANK;
        } else {
          filterOption = IS_EQUAL_TO;
        }
      }
    }
    values = values.map((val) => val.replace(/,/g, (x) => `^${x}`));
    filterValue = values.join(',');
    switch (filterOption) {
      case IS_EQUAL_TO:
        value += `${filterValue}`;
        break;
      case IS_NOT_EQUAL_TO:
        value += values.map((x) => `-${x}`).join(',');
        break;
      case STARTS_WITH:
        value += values.map((x) => `${x}%`).join(',');
        break;
      case ENDS_WITH:
        value += values.map((x) => `%${x}`).join(',');
        break;
      case CONTAINS:
        value += values.map((x) => `%${x}%`).join(',');
        break;
      case IS_BLANK:
      case IS_NULL:
        value += 'EMPTY';
        break;
      case DOESNT_START_WITH:
        value += values.map((x) => `-${x}%`).join(',');
        break;
      case DOESNT_END_WITH:
        value += values.map((x) => `-%${x}`).join(',');
        break;
      case DOESNT_CONTAIN:
        value += values.map((x) => `-%${x}%`).join(',');
        break;
      case IS_NOT_BLANK:
      case IS_NOT_NULL:
        value += '-EMPTY';
        break;
      default:
        value += filterValue;
    }
  }
  return encodeURIComponent(value);
}

// Get Looker query string or object for a set of filters
export function getLookerQueryString(filterSet, string) {
  const filterDelimiter = '_';
  let filterString = '';
  const filterObj = {};

  filterSet.filterIds.forEach((filterId) => {
    const filter = filterSet[filterId];
    const filterKey = filter.fieldName;
    const filterValueObj = filter.value;
    let value = '';

    if ((filterValueObj && (filterValueObj.value || filterValueObj.option)) || filter.type === 'date') {
      if (string && filterString) {
        filterString += filterDelimiter;
      }

      // Special case the date filter for additional parsing
      // This is because Looker strings are not consistent
      // BETWEEN can be both "a to b" and "a,b"
      // NOT can be both "-" and "not "
      if (filter.type === 'date') {
        const dateOption = (filterValueObj && filterValueObj.dateOption) ? filterValueObj.dateOption : '';
        switch (dateOption) {
          case IS_AFTER:
            value += `after ${filterValueObj.primaryDateField}`;
            break;
          case IS_BEFORE:
            value += `before ${filterValueObj.primaryDateField}`;
            break;
          case MATCHES:
            value += `${filterValueObj.primaryDateField}`;
            break;
          case IS_BETWEEN:
            value += `${filterValueObj.primaryDateField} to ${filterValueObj.secondaryDateField}`;
            break;
          case IS_IN_THE_PAST:
            value += `${filterValueObj.absoluteValue} ${filterValueObj.absoluteValueModifier}`;
            break;
          case IS_IN: {
            const { startDate, endDate } = calculateDateRange(filterValueObj.monthValue, filterValueObj.yearValue);
            value += `${startDate} to ${endDate}`;
            break;
          }
        }
      } else {
        let filterOption = (filterValueObj && filterValueObj.option) ? filterValueObj.option : '';
        let filterValue;
        let values;
        if (filter.type === 'text') {
          const { mappedValues } = filter || null;
          filterValue = (filterValueObj && filterValueObj.value) ? filterValueObj.value : [];
          if (!Array.isArray(filterValue)) {
            filterValue = filterValue.split(/,/g);
          }
          values = filterValue;
          if (mappedValues) {
            if (![IS_BLANK, IS_NULL, IS_NOT_BLANK, IS_NOT_NULL].includes(filterOption)) {
              values = convertMappedValues(mappedValues, filterOption, values);
              if (values.length === 0) {
                filterOption = IS_BLANK;
              } else {
                filterOption = IS_EQUAL_TO;
              }
            }
          }
          values = values.map((val) => val.replace(/,|%|_|\^/g, (x) => `^${x}`));
          filterValue = values.join(',');
        } else {
          filterValue = (filterValueObj && filterValueObj.value) ? filterValueObj.value : '';
          values = filterValue.split(/,/g);
        }
        switch (filterOption) {
          case IS_EQUAL_TO:
            value += `${filterValue}`;
            break;
          case IS_GREATER_THAN:
            value += `>${filterValue}`;
            break;
          case IS_GREATER_THAN_OR_EQUAL_TO:
            value += `>=${filterValue}`;
            break;
          case IS_LESS_THAN:
            value += `<${filterValue}`;
            break;
          case IS_LESS_THAN_OR_EQUAL_TO:
            value += `<=${filterValue}`;
            break;
          case IS_BETWEEN:
          case IS_NOT_BETWEEN:
            if (filterValueObj.option === IS_NOT_BETWEEN) {
              value += 'not ';
            }
            if (filterValueObj.secondaryOption === 'inclusive' || filterValueObj.secondaryOption === 'right-exclusive') {
              value += '[';
            } else {
              value += '(';
            }
            value += `${filterValue},${filterValueObj.secondaryValue}`;
            if (filterValueObj.secondaryOption === 'inclusive' || filterValueObj.secondaryOption === 'left-exclusive') {
              value += ']';
            } else {
              value += ')';
            }
            break;
          case IS_NOT_EQUAL_TO:
            if (filter.type === 'number') {
              value += 'not ';
              value += values.map((x) => `${x}`).join(',');
            } else {
              value += values.map((x) => `-${x}`).join(',');
            }
            break;
          case STARTS_WITH:
            value += values.map((x) => `${x}%`).join(',');
            break;
          case ENDS_WITH:
            value += values.map((x) => `%${x}`).join(',');
            break;
          case CONTAINS:
            value += values.map((x) => `%${x}%`).join(',');
            break;
          case IS_BLANK:
            value += 'EMPTY';
            break;
          case IS_NULL:
            value += 'NULL';
            break;
          case DOESNT_START_WITH:
            value += values.map((x) => `-${x}%`).join(',');
            break;
          case DOESNT_END_WITH:
            value += values.map((x) => `-%${x}`).join(',');
            break;
          case DOESNT_CONTAIN:
            value += values.map((x) => `-%${x}%`).join(',');
            break;
          case IS_NOT_BLANK:
            value += '-EMPTY';
            break;
          case IS_NOT_NULL:
            value += '-NULL';
            break;
          default:
            value += filterValue;
        }
      }
    }
    if (string) {
      filterString += `${filterKey}=${encodeURIComponent(value)}`;
    } else {
      filterObj[filterKey] = value;
    }
  });
  return string ? filterString : filterObj;
}

// Parse Looker query string for a set of filters, based on Looker filter string format
// (outlined here: https://docs.looker.com/reference/filter-expressions)
export function parseLookerQueryString(queryString, portalFilters, drillFilters) {
  const filters = new FilterSet();
  Object.keys(queryString).forEach((filterKey) => {
    const filterId = portalFilters.filterIds.find((id) => portalFilters[id].fieldName === filterKey);
    if (filterId) {
      const portalFilter = portalFilters[filterId];
      const queryValue = queryString[filterKey];
      const filter = new Filter();
      const filterValue = {};

      if (portalFilter.type === 'date') {
        // Special case the date filter for additional parsing
        // This is because Looker strings are not consistent
        // BETWEEN can be both "a to b" and "a,b"
        // NOT can be both "-" and "not "
        if (!queryValue) {
          filterValue.dateOption = IS_ANY;
        } else if (queryValue.includes('after')) {
          filterValue.dateOption = IS_AFTER;
          filterValue.primaryDateField = queryValue.split(' ')[1].replace(/\//g, '-');
        } else if (queryValue.includes('before')) {
          filterValue.dateOption = IS_BEFORE;
          filterValue.primaryDateField = queryValue.split(' ')[1].replace(/\//g, '-');
        } else if (queryValue.includes('to')) {
          const startValue = queryValue.split(' ')[0].replace(/\//g, '-');
          const endValue = queryValue.split(' ')[2].replace(/\//g, '-');
          const startMonth = (parseInt(startValue.split('-')[1]) - 1).toString();
          const startYear = startValue.split('-')[0];
          const { startDate, endDate } = calculateDateRange(startMonth, startYear);
          if (startValue === startDate && endValue === endDate) {
            filterValue.dateOption = IS_IN;
            filterValue.monthValue = startMonth;
            filterValue.yearValue = startYear;
          } else {
            filterValue.dateOption = IS_BETWEEN;
            filterValue.primaryDateField = startValue;
            filterValue.secondaryDateField = endValue;
          }
        } else if (queryValue.includes(' ')) {
          filterValue.dateOption = IS_IN_THE_PAST;
          [filterValue.absoluteValue, filterValue.absoluteValueModifier] = queryValue.split(' ');
        } else {
          filterValue.dateOption = MATCHES;
          filterValue.primaryDateField = queryValue.replace(/\//g, '-');
        }
      } else if (drillFilters) {
        filterValue.option = IS_EQUAL_TO;
        filterValue.value = [queryValue.replace(/\^_/g, '_').replace(/\^%/g, '%')];
      } else if (queryValue.substring(0, 2) === '>=') {
        filterValue.option = IS_GREATER_THAN_OR_EQUAL_TO;
        filterValue.value = queryValue.substring(2);
      } else if (queryValue[0] === '>') {
        filterValue.option = IS_GREATER_THAN;
        filterValue.value = queryValue.substring(1);
      } else if (queryValue.substring(0, 2) === '<=') {
        filterValue.option = IS_LESS_THAN_OR_EQUAL_TO;
        filterValue.value = queryValue.substring(2);
      } else if (queryValue[0] === '<') {
        filterValue.option = IS_LESS_THAN;
        filterValue.value = queryValue.substring(1);
      } else if (queryValue.includes('%')) {
        const negative = queryValue[0] === '-';
        const queryValues = queryValue.replace('-', '');
        if (queryValues.substring(0, 1) === '"') {
          filterValue.option = negative ? IS_NOT_EQUAL_TO : IS_EQUAL_TO;
          filterValue.value = queryValues.replace(/"/g, '');
        } else {
          if (queryValues[0] === '%' && queryValues.slice(-1) === '%') {
            filterValue.option = negative ? DOESNT_CONTAIN : CONTAINS;
          } else if (queryValues[0] === '%') {
            filterValue.option = negative ? DOESNT_END_WITH : ENDS_WITH;
          } else if (queryValues.slice(-1) === '%') {
            filterValue.option = negative ? DOESNT_START_WITH : STARTS_WITH;
          }
          filterValue.value = queryValues.replace(/%/g, '');
        }
      } else if (['[', '(', ')', ']'].some((char) => queryValue.includes(char))) {
        let queryRange;
        if (queryValue.includes('not')) {
          filterValue.option = IS_NOT_BETWEEN;
          [, queryRange] = queryValue.split('not ');
        } else {
          filterValue.option = IS_BETWEEN;
          queryRange = queryValue;
        }
        if (queryRange[0] === '[' && queryRange.slice(-1) === ']') {
          filterValue.secondaryOption = 'inclusive';
        } else if (queryRange[0] === '(' && queryRange.slice(-1) === ')') {
          filterValue.secondaryOption = 'exclusive';
        } else if (queryRange[0] === '[') {
          filterValue.secondaryOption = 'right-exclusive';
        } else {
          filterValue.secondaryOption = 'left-exclusive';
        }
        [filterValue.value, filterValue.secondaryValue] = queryRange.replace(/[[\]() ]/g, '').split(',');
      } else if (queryValue.includes('not')) {
        filterValue.option = IS_NOT_EQUAL_TO;
        filterValue.value = queryValue.replace('not ', '');
      } else if (queryValue === 'EMPTY') {
        filterValue.option = IS_BLANK;
        filterValue.value = null;
      } else if (queryValue === 'NULL') {
        filterValue.option = IS_NULL;
        filterValue.value = null;
      } else if (queryValue === '-EMPTY') {
        filterValue.option = IS_NOT_BLANK;
        filterValue.value = null;
      } else if (queryValue === '-NULL') {
        filterValue.option = IS_NOT_NULL;
        filterValue.value = null;
      } else if (queryValue[0] === '-') {
        filterValue.option = IS_NOT_EQUAL_TO;
        filterValue.value = queryValue.substring(1);
      } else {
        filterValue.option = IS_EQUAL_TO;
        filterValue.value = queryValue;
      }
      if (portalFilter.type === 'text' && filterValue.value) {
        if (!Array.isArray(filterValue.value)) {
          filterValue.value = filterValue.value.split(/,(?=\S)/g);
        }
        const { mappedValues } = portalFilter || null;
        if (mappedValues) {
          filterValue.value = reverseConvertMappedValues(mappedValues, filterValue.option, filterValue.value);
          if (!filterValue.value) {
            filterValue.option = IS_BLANK;
          } else {
            filterValue.option = IS_EQUAL_TO;
          }
        }
      }
      filter.value = filterValue;
      filters[filterId] = filter;
    }
  });
  return filters;
}

// Determine if a filter has a non-default value or not
export function filterHasValue(filter, filterDef, ignoreDashboardDefault) {
  const defaultValue = (filterDef.defaultValue && objectsEqual(filterDef.defaultValue, filter.value)) ||
    (!ignoreDashboardDefault && filterDef.dashboardDefaultValue &&
      objectsEqual(filter.dashboardDefaultValue, filter.value));
  const emptyValue = filterDef.type !== 'date' &&
    (!filter.value.value || (Array.isArray(filter.value.value) && !filter.value.value[0]));
  const emptyOption = filterDef.type !== 'date' && (filter.value.option === IS_EQUAL_TO || !filter.value.option);
  if ((!defaultValue && emptyValue && emptyOption) || defaultValue) {
    return false;
  }
  return true;
}

export default {
  getFilterSummary,
  getLookerQueryString,
  filterHasValue,
  parseLookerQueryString,
};
