// Polyfill for IE11
// From https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
((arr) => {
  arr.forEach((item) => {
    /* eslint no-prototype-builtins: "off" */
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
