<template>
  <v-container
    fluid
    class="pt-2 intelligence-report-card">
    <v-card>
      <div class="report-card-container">
        <div>
          <div>
            <h3 class="text-h6">
              {{ report.title }}
            </h3>
          </div>
          <div>
            <h5 class="text-caption mt-1">
              Created: {{ formatDate(report.date_created) }}
            </h5>
          </div>
        </div>
        <div class="report-actions">
          <v-btn
            v-if="isPDF(downloadLink) && !fileLoading"
            color="primary"
            @click="linkAction(downloadLink, 'preview')">
            <strong>
              Preview
            </strong>
            <v-icon
              class="float-right pl-4"
              icon="mdi-magnify"/>
          </v-btn>
          <div
            v-if="fileLoading"
            class="d-flex h-100">
            <v-progress-circular
              indeterminate
              color="primary"
              class="loader"/>
          </div>
          <v-btn
            v-if="!fileLoading"
            color="secondary"
            @click="linkAction(downloadLink, 'downloading')">
            <strong>
              Download
            </strong>
            <v-icon
              class="float-right pl-4"
              icon="mdi-download"/>
          </v-btn>
        </div>
      </div>
      <v-card-text>
        <div
          class="pa-4 summary"
          v-html="summary"/>
      </v-card-text>
    </v-card>
    <FileViewer
      v-if="selectedFile"
      :is-visible="showFileViewer"
      :source-file="selectedFile"
      @onModalClose="() => showFileViewer = false"/>
    <a
      id="downloadFrame"
      style="display: none;"/>
  </v-container>
</template>
<script>
import * as Sentry from '@sentry/browser';
import { marked } from 'marked';
import moment from 'moment';
import { EventLog } from '@/lib/event-log';
import FileViewer from '@/components/utils/viewers/FileViewer.vue';

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: true,
});

export default {
  name: 'ReportCard',
  components: {
    FileViewer,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
      downloadSpeedDial: false,
      selectedFile: null,
      blobFile: null,
      linkPath: null,
      fileLoading: false,
      timeId: null,
      showFileViewer: false,
      hasBeenPreviewed: false,
    };
  },
  computed: {
    downloadLink() {
      return this.report.download_links[0];
    },
    summary() {
      return marked(this.report.summary);
    },
  },
  methods: {
    isPDF(link) {
      return link.indexOf('.pdf') !== -1;
    },
    async linkAction(path, action) {
      if (action === 'preview') {
        this.hasBeenPreviewed = true;
        this.showFileViewer = true;
      }

      if (!this.blobFile && !this.fileLoading) await this.prepareBlob(path, false);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(this.blobFile, this.linkPath);
      } else if (action === 'downloading') {
        const link = document.getElementById('downloadFrame');
        link.href = this.blobFile;
        link.download = this.linkPath;
        link.click();
      }
      const loggingData = new EventLog({
        event: 'report.download',
        reportFilePath: path,
        reportName: this.report.title,
      });
      this.$services.users.postTrackingLog(loggingData);
    },
    async prepareBlob(path, retry) {
      const filePath = encodeURIComponent(path);
      this.fileLoading = true;
      // Do a sanity check to verify the file exists and that our token is still valid, before downloading the file.
      await this.$services.files.getFileList(filePath).then(async () => {
        await this.$services.files.getFileDownload(filePath).then(async (response) => {
          await response.blob().then(async (file) => {
            if (this.isPDF(path)) this.selectedFile = new File([file], 'Downloaded PDF', { type: 'application/pdf' });
            this.blobFile = URL.createObjectURL(file);
            this.linkPath = path.split('/')[path.split('/').length - 1];
            this.fileLoading = false;
          }).catch((error) => {
            this.prepareBlobError(path, error);
          });
        }).catch((error) => {
          if (retry) {
            this.prepareBlobError(path, error);
          } else {
            this.prepareBlob(path, true);
          }
        });
      }).catch((error) => {
        if (retry) {
          this.prepareBlobError(path, error);
        } else {
          this.prepareBlob(path, true);
        }
      });
    },
    prepareBlobError(path, error) {
      this.$notify('There was a problem downloading this report');
      const failLog = new EventLog({
        event: 'report.failed_download',
        reportFilePath: path,
        reportName: this.report.title,
      });
      this.$services.users.postTrackingLog(failLog);
      Sentry.withScope((scope) => {
        Sentry.setContext('action_attributes', {
          module: 'intelligence',
          action: 'download',
          report_file_path: path,
          report_name: this.report.title,
        });
        scope.setLevel('warning');
        Sentry.captureException(new Error(error));
      });
    },
    formatDate(date) {
      return moment(date).utc().format('dddd, MMMM Do YYYY');
    },
  },
};
</script>

<style lang="scss">
.intelligence-report-card {
  width: 1000px;
  .title {
    font-size: 20px !important;
    word-break: break-word;
  }
  .caption {
    font-size: 12px !important;
  }
  .lift-above-cards {
    z-index: 5;
  }
  .report-card-actions {
    cursor: pointer;
  }
  .report-card-meta {
    opacity: .7;
  }
  .remove-text-transform {
    text-transform: none;
  }
  .summary {
    table {
      width: 100%;
      border: 1px solid #ccc;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      table-layout: fixed;
      caption {
        font-size: 1.5em;
        margin: .5em 0 .75em;
      }
      tr {
        background: #f8f8f8;
        border: 1px solid #ddd;
        padding: .35em;
      }
      th {
        display: none;
      }
      td {
        padding: .625em;
        input {
          width: 100%;
        }
        &:first-child {
          font-weight: bold;
        }
        &:nth-child(2) {
          input {
            text-align: right;
          }
        }
      }
    }
  }
}

.report-card-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}
.report-actions {
  margin-left: 1rem;
}
.download-link {
  margin: .5rem;
  border-radius: .25rem;
  border: 1px solid #ddd;
  box-shadow: .5px 1px #888888;
}
.pdf-content {
  height: calc(100% - 2rem);
  width: calc(90% - 2rem);
  margin: auto;
  border: 2px solid #ddd;
}
</style>
