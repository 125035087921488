import SourceService from '@/services/sources';

export default {
  state: {
    sources: [],
    sourcesLoaded: false,
  },
  mutations: {
    RESET_SOURCES(state) {
      state.sources = [];
      state.sourcesLoaded = false;
    },
    SET_SOURCES(state, sources) {
      state.sources = sources;
    },
    SET_SOURCE(state, source) {
      const sourceId = source.id;
      state.sources = [...state.sources.filter((s) => s.id !== sourceId), source];
    },
    SET_SOURCES_LOADED(state, sourcesLoaded) {
      state.sourcesLoaded = sourcesLoaded;
    },
  },
  actions: {
    resetSources({ commit }) {
      commit('RESET_SOURCES');
    },
    setSources({ commit }) {
      commit('SET_SOURCES_LOADED', false);
      return new Promise((resolve, reject) => {
        SourceService.getSources().then((sources) => {
          commit('SET_SOURCES', sources);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('SET_SOURCES_LOADED', true);
        });
      });
    },
    setSource({ commit }, sourceId) {
      return new Promise((resolve, reject) => {
        SourceService.getSource(sourceId).then((source) => {
          commit('SET_SOURCE', source);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
  getters: {
    sourceFailed: (state) => {
      let sourceFailed = false;
      state.sources.forEach((source) => {
        const { status } = source;
        if (status) {
          const fetchStatus = source.status.find((s) => s.type === 'fetch');
          const loadStatus = source.status.find((s) => s.type === 'load');
          if ((fetchStatus && fetchStatus.status === 'failed') || (
            fetchStatus && fetchStatus.status === 'succeeded' && loadStatus && loadStatus.status === 'failed')) {
            sourceFailed = true;
          }
        }
      });
      return sourceFailed;
    },
    sources: (state) => state.sources,
    sourcesLoaded: (state) => state.sourcesLoaded,
  },
};
