import {
  POST_METHOD, PUT_METHOD, DELETE_METHOD, Request,
} from '@/lib/http';

const baseURL = '/api/filters';

export default {
  getFilters(preventAbort) {
    const request = new Request(`${baseURL}/`, { preventAbort });
    return request.send();
  },
  getFilterSet(filterSetId) {
    const request = new Request(`${baseURL}/filter_sets/${filterSetId}`);
    return request.send();
  },
  getUserFilterSets(userId, preventAbort) {
    const request = new Request(`${baseURL}/user_filter_sets/${userId}`, { preventAbort });
    return request.send();
  },
  postFilterSet(filterSet) {
    const request = new Request(`${baseURL}/filter_sets`, {
      body: filterSet, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postUserFilterSet(userId, userFilterSet) {
    const request = new Request(`${baseURL}/user_filter_sets/${userId}`, {
      body: userFilterSet, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  putUserFilterSet(userId, userFilterSetId, userFilterSet) {
    const request = new Request(`${baseURL}/user_filter_sets/${userId}/${userFilterSetId}`, {
      body: userFilterSet, method: PUT_METHOD, preventAbort: true,
    });
    return request.send();
  },
  deleteUserFilterSet(userId, userFilterSetId) {
    const request = new Request(`${baseURL}/user_filter_sets/${userId}/${userFilterSetId}`, {
      method: DELETE_METHOD, preventAbort: true,
    });
    return request.send();
  },
  postDefaultUserFilterSet(userId, userFilterSetId) {
    const request = new Request(`${baseURL}/default_user_filter_set/${userId}`, {
      body: userFilterSetId, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  putDefaultUserFilterSet(userId, userFilterSetId) {
    const request = new Request(`${baseURL}/default_user_filter_set/${userId}`, {
      body: userFilterSetId, method: PUT_METHOD, preventAbort: true,
    });
    return request.send();
  },
  deleteDefaultUserFilterSet(userId) {
    const request = new Request(`${baseURL}/default_user_filter_set/${userId}`, {
      method: DELETE_METHOD, preventAbort: true,
    });
    return request.send();
  },
};
