<template>
  <div class="analytics-view h-100">
    <component
      :is="viewType"
      :is-visible="isVisible"
      :dashboard-def="dashboardDef"
      :drill-target="drillTarget"
      :filter-values="filterValues"
      :dashboard-load-status="dashboardLoadStatus"
      :reload="reload"
      @updateLoadStatus="(loadStatus) => $emit('updateLoadStatus', loadStatus)"
      @drillAction="(state) => $emit('drillAction', state)"
      @linkAction="(filterSet) => $emit('linkAction', filterSet)"
      @cleanFilters="() => $emit('cleanFilters')"/>
  </div>
</template>

<script>
import LookerView from './LookerView/LookerView.vue';

export default {
  name: 'AnalyticsView',
  components: {
    LookerView,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    dashboardDef: {
      type: Object,
      required: true,
    },
    drillTarget: {
      type: Object,
      default: () => {},
      required: false,
    },
    filterValues: {
      type: Object,
      required: true,
    },
    dashboardLoadStatus: {
      type: String,
      required: true,
    },
    reload: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    viewType() {
      if (this.dashboardDef.type === 'looker' || this.dashboardDef.type === 'drill_through') {
        return 'LookerView';
      }
      return null;
    },
  },
};
</script>
