// compare.js
// Comparison functions for arrays and objects.

import cloneDeep from 'lodash.clonedeep';

// Compare two arrays and return true if they have the same elements, regardless of order
export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  const c = [...a].sort();
  const d = [...b].sort();
  for (let i = 0; i < c.length; i++) {
    if (c[i].constructor.name === 'Object') {
      const cKeys = Object.keys(c[i]);
      const dKeys = Object.keys(d[i]);
      if (!arraysEqual(cKeys, dKeys)) return false;
      for (let j = 0; j < cKeys.length; j++) {
        const key = cKeys[j];
        if ((Array.isArray(c[i][key]) && Array.isArray(d[i][key])) && arraysEqual(c[i][key], d[i][key])) return true;
        if (c[i][key] !== d[i][key]) return false;
      }
      return true;
    } else if (c[i] !== d[i]) return false;
  }
  return true;
}

// Compare two objects and return true if they have the same values for each key
export function objectsEqual(a, b, filterPredicate) {
  if (a === b) return true;
  if (a == null || b == null) return false;

  let aKeys;
  let bKeys;

  // Filter out keys if predicate is provided
  if (filterPredicate) {
    aKeys = filterPredicate(Object.keys(a));
    bKeys = filterPredicate(Object.keys(b));
  } else {
    aKeys = Object.keys(a);
    bKeys = Object.keys(b);
  }

  if (!arraysEqual(aKeys, bKeys)) return false;
  for (let i = 0; i < aKeys.length; i++) {
    const key = aKeys[i];
    if (Array.isArray(a[key]) && Array.isArray(b[key])) {
      if (!arraysEqual(a[key], b[key])) {
        return false;
      }
    } else if (typeof a[key] === 'object' && a[key] !== null && typeof b[key] === 'object' && b[key] !== null) {
      if (!objectsEqual(a[key], b[key])) {
        return false;
      }
    } else if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

// Get private keys (prefaced with `_`) from list of object keys
export function getPrivateKeys(keys) {
  return keys.filter((x) => x.startsWith('_'));
}

// Filter out private keys (prefaced with `_`) from list of object keys
export function removePrivateKeys(keys) {
  return keys.filter((x) => !x.startsWith('_'));
}

// Filter out private keys (prefaced with `_`) from object
export function removePrivateKeysFromObj(obj) {
  if (!(obj instanceof Object)) return null;
  const newObj = cloneDeep(obj);
  const keys = getPrivateKeys(Object.keys(obj));
  keys.forEach((key) => { delete newObj[key]; });
  return newObj;
}

export default {
  arraysEqual,
  objectsEqual,
  getPrivateKeys,
  removePrivateKeys,
  removePrivateKeysFromObj,
};
