<template>
  <div>
    <ContentModal
      :is-visible="true"
      :hide-close="true"
      :allow-click-away="false"
      size="small"
      @onModalClose="closeModal()">
      <template
        #header-content>
        <div class="upload-header">
          File Upload
        </div>
      </template>
      <template
        #content>
        <div class="loading-content">
          <div style="text-align: left; font-size: 14px;">
            {{ progressText }}
          </div>
          <div style="display: flex;">
            <v-progress-linear
              style="margin-top: 5px;"
              :height="20"
              :model-value="progressBar"
              :buffer-value="100"
              bg-color="#CAD1D5"
              color="secondary"
              striped
              rounded>
              <div style="font-weight: 600; font-size: 14px;">
                {{ Math.floor(progressBar) }}%
              </div>
            </v-progress-linear>
          </div>
          <v-btn
            id="close-uploading-btn"
            :color="progressBar < 100 ? '#e5e8ea' : 'primary'"
            @click="closeModal()">
            {{ closeBtnText }}
          </v-btn>
        </div>
      </template>
    </ContentModal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ContentModal from '@/components/utils/modal/ContentModal.vue';
import { runFileReader } from '@/components/utils/file-upload/upload-utils';

export default {
  name: 'FileUpload',
  components: {
    ContentModal,
  },
  props: {
    uploadPackage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chunks: 0,
      chunkSize: 20480000,
      completedChunks: 0,
      uploadedTotal: 0,
      batchSize: 25,
      progressBar: 0,
      progressText: 'Loading...',
      uploadCanceled: false,
    };
  },
  computed: {
    closeBtnText() {
      return this.progressBar < 100 ? 'Cancel' : 'Done';
    },
  },
  mounted() {
    this.startUpload();
  },
  methods: {
    ...mapActions([
      'putUploadFile',
      'putUploadChunks',
      'fetchFile',
    ]),
    closeModal() {
      if (this.closeBtnText === 'Cancel') this.uploadCanceled = true;

      this.$emit('onUploadingDone');
    },
    async startUpload() {
      this.uploadProgress(5, 'Initializing...');
      await this.putUploadFile(this.getUploadBody({ includeBlobRefs: false }));
      this.uploadProgress(10, 'Starting Upload...');
      this.chunks = Math.ceil(this.uploadPackage.size / this.chunkSize);
      const end = this.batchSize < this.chunks ? this.batchSize : this.chunks;
      this.uploadChunkFile(0, end);
    },

    uploadProgress(progressBar, progressText) {
      this.progressBar = progressBar;
      this.progressText = progressText;
    },
    async uploadChunkFile(startChunk, endChunk) {
      const promises = [];

      for (let currentChunk = startChunk; currentChunk < endChunk; currentChunk++) {
        const start = currentChunk * this.chunkSize;
        const potentialEnd = start + this.chunkSize;
        const end = potentialEnd >= this.uploadPackage.size ? this.uploadPackage.size : potentialEnd;
        const fileSlice = this.uploadPackage.file.slice(start, end);

        // Upload file slice and push process to Promises Array that can wait until all chunks have been uploaded
        promises.push(runFileReader(fileSlice).then(async (fileChunk) => {
          const chunkData = window.btoa(Array.from(new Uint8Array(fileChunk.data)).map((b) => String.fromCharCode(b)).join(''));
          const progressTick = 80 / this.chunks;
          const body = {
            group: 'files',
            version: 'v1',
            kind: 'Blob',
            meta: {
              name: `sha256_${fileChunk.hash}`,
            },
            spec: {
              contentHash: `sha256_${fileChunk.hash}`,
              size: fileChunk.size,
            },
            data: chunkData,
          };
          await this.putUploadChunks(body);
          this.completedChunks++;
          this.uploadedTotal += fileChunk.size;
          const currentTick = progressTick * (this.completedChunks) + 10;
          this.uploadProgress(currentTick, `Uploading ${this.getSizeText(this.uploadedTotal)} / ${this.getSizeText(this.uploadPackage.size)}`);
        }));
      }

      // After all file chunks have been uploaded
      await Promise.all(promises).then(async () => {
        if (!this.uploadCanceled) {
          if (endChunk < this.chunks) {
            if (endChunk + this.batchSize < this.chunks) {
              this.uploadChunkFile(endChunk, endChunk + this.batchSize);
            } else {
              this.uploadChunkFile(endChunk, this.chunks);
            }
          } else {
            this.uploadProgress(95, 'Finalizing...');
            await this.putUploadFile(this.getUploadBody({ includeBlobRefs: true }));
            this.uploadProgress(100, 'Upload Complete');
            this.fetchFile(this.uploadPackage.fileHash);
          }
        }
      });
    },
    getUploadBody({ includeBlobRefs }) {
      const body = {
        group: 'files',
        version: 'v1',
        kind: 'File',
        meta: {
          name: this.uploadPackage.fileHash,
          labels: {
            inbox: '',
          },
        },
        spec: {
          name: this.uploadPackage.fileName,
          contentType: this.uploadPackage.contentType,
          expectedTotalBytes: this.uploadPackage.size,
        },
      };

      if (includeBlobRefs) {
        body.spec.sourceBlobRefs = this.uploadPackage.sourceBlobRefs;
      }
      return body;
    },
    getSizeText(size) {
      let chunkSize;
      if (size < 1000000) {
        chunkSize = Number.parseFloat((size / 1000).toFixed(2));
        return `${chunkSize} KB`;
      } else if (size < 1000000000) {
        chunkSize = Number.parseFloat((size / 1000000).toFixed(2));
        return `${chunkSize} MB`;
      } else {
        chunkSize = Number.parseFloat((size / 1000000000).toFixed(2));
        return `${chunkSize} GB`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/colors.scss";

#close-uploading-btn {
  margin: 15px !important;
  margin-top: 25px !important;
  height: 40px;
  width: 120px;
  font-weight: 600;
}

.upload-header {
  margin: 5px;
  margin-left: 1rem;
}

.loading-content {
  margin: 1rem;
}
</style>
