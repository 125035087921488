<template>
  <ContentModal
    :is-visible="isVisible"
    @onModalClose="() => $emit('onModalClose')">
    <template
      #content>
      <div
        v-if="!loaded"
        class="d-flex h-100">
        <v-progress-circular
          indeterminate
          color="primary"
          class="loader"/>
      </div>
      <div v-else>
        <div id="pdfContainer">
          <div
            v-if="loaded"
            id="viewer"
            class="pdfViewer">
            <div
              v-for="(pageNum, index) in pdf.numPages"
              :key="index">
              <PdfPage
                v-if="isVisible"
                :page="pages[pageNum - 1]"
                :page-num="pageNum"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ContentModal>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import ContentModal from '@/components/utils/modal/ContentModal.vue';
import { markRaw } from 'vue';
import PdfPage from './PdfPage.vue';
import 'pdfjs-dist/build/pdf.worker.mjs';

const pdfjsWorker = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

export default {
  name: 'PdfViewer',
  components: {
    ContentModal,
    PdfPage,
  },
  props: {
    source: {
      type: File,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pdf: null,
      loaded: false,
      pages: [],
    };
  },
  watch: {
    source(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.loaded = false;
        this.getPdf();
      }
    },
  },
  mounted() {
    this.getPdf();
  },
  methods: {
    getPdf() {
      this.blobFile = URL.createObjectURL(this.source);
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
      pdfjsLib.getDocument(this.blobFile).promise.then(this.setupPdf);
    },
    setupPdf(pdf) {
      const promises = [];
      this.pages = [];
      for (let i = 1; i <= pdf.numPages; i++) {
        promises.push(pdf.getPage(i).then((page) => {
          const marked = markRaw(page);
          this.pages.push(marked);
        }));
      }
      Promise.all(promises).then(() => {
        this.pdf = pdf;
        this.loaded = true;
      });
    },
  },
};
</script>

<style>
#pdfContainer {
  margin: auto;
  width: 100%;
}

div.page {
  display: inline-block;
}

.pdfViewer {
  height: 100%;
  width: 100%;
}
</style>
