<template>
  <span
    v-if="!isActive"
    class="auto-vertical-margins">
    <v-tooltip
      v-if="!isActive"
      location="bottom">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          color="primary"
          size="small"
          variant="text"
          icon
          @click="removeFilter">
          <v-icon icon="mdi-close"/>
        </v-btn>
      </template>
      <span>Click to remove.</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'FilterActions',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter');
    },
  },
};
</script>
