<template>
  <div
    class="list-header"
    :class="divider ? 'header-divider' : ''"
    @click="() => $emit('sort-by', sorterType)">
    {{ headerName }}
    <span v-if="sorter">
      <v-icon
        v-if="sorter.direction === 'ascending'"
        :size="15"
        icon="mdi-chevron-up"/>
      <v-icon
        v-else
        :size="15"
        icon="mdi-chevron-down"/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ListHeader',
  props: {
    headerName: {
      type: String,
      required: true,
    },
    sorterType: {
      type: String,
      required: true,
    },
    sorter: {
      type: Object,
      default: () => {},
    },
    divider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.list-header {
  cursor: pointer;

  &.header-divider {
    border-left: 1px solid $bainbridge-gray-light;
    padding-left: .5rem;
  }

  &:active {
    background-color: $bainbridge-gray-light;
  }
}

</style>
